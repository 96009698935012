import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { DeleteOutlined, MoreHoriz, Search } from '@material-ui/icons';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { convertSecondsToTimeStamp, dateToTimeElapsed } from '../../../services/timeStampService';
import { getDetails, getLowQThumbnailFromRenditions } from '../../../services/mediaDisplayService';
import { mediaActionKeys, mediaTypes, messageTypes } from '../../../constants/mediaConstants';
import no_thumbnail from '../../../assets/svg/NoThumbnail.svg';
import {
	changeTheOrderOfSelectedMediaItemInPlaylist,
	handlePlaylistMediaLoadingAction,
	loadPlaylistRelativeMedias,
	removeSelectedMediaItemFromThePlaylist,
} from '../../../actions/publishActions';
import '../../../reusable/MediaDisplay/ListMediasDisplay.scss';
import { getInspectLink } from '../../../utils/commonUtil';
import { showMessage } from '../../../actions/globalActions';

interface PlaylistMediasProps {
	isMobileView?: boolean;
	playListId?: string;
	mediaDetails: any;
}

const mediaContainerBaseClassName = 'media-item-container';

export const PlaylistMedias: React.FC<PlaylistMediasProps> = ({ isMobileView, mediaDetails }) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const { defaultAccountId } = useSelector((state) => (state as any).session);

	const listMediaRef = useRef<HTMLDivElement | null>(null);
	const [medias, setMedias] = useState<any[]>([]);
	const [playlistMetaData, setPlaylistMetaData] = useState<any>({});
	const [smartPlaylist, setSmartPlaylist] = useState(false);
	const [scrollSize, setScrollSize] = useState<number>(0);

	const loadTheMediaItems = useCallback(() => {
		dispatch(loadPlaylistRelativeMedias(defaultAccountId, mediaDetails.id)).then((data: any) => {
			if (data && data.medias) {
				setSmartPlaylist(false);
				setMedias(_.sortBy(data.medias, 'order'));
			} else {
				dispatch(handlePlaylistMediaLoadingAction(defaultAccountId, mediaDetails.id)).then((res: any) => {
					setSmartPlaylist(true);
					if (res) {
						setMedias(_.sortBy(res, 'order'));
					} else {
						setMedias([]);
					}
				});
			}
			setPlaylistMetaData(data ? data.metadata : {});
			checkScrollBar();
		});
	}, [defaultAccountId, dispatch, mediaDetails.id]);

	const onDragEnd = useCallback(
		(result: any) => {
			const oldIndex = result?.source?.index;
			const newIndex = result?.destination?.index;
			if (result?.source && result?.destination && oldIndex !== newIndex) {
				const delta = newIndex > oldIndex ? -1 : 1; // down - up
				let tempPlaylistMedias: any[] = medias;

				for (const index in tempPlaylistMedias) {
					const mediaIndex = parseInt(index);
					if (mediaIndex === oldIndex) {
						tempPlaylistMedias[mediaIndex].order = newIndex + 1;
					}
					if (delta < 0 && mediaIndex > oldIndex && mediaIndex <= newIndex) {
						tempPlaylistMedias[mediaIndex].order = tempPlaylistMedias[mediaIndex].order + delta;
					}
					if (delta > 0 && mediaIndex < oldIndex && mediaIndex >= newIndex) {
						tempPlaylistMedias[mediaIndex].order = tempPlaylistMedias[mediaIndex].order + delta;
					}
				}

				setMedias(_.sortBy(tempPlaylistMedias, 'order'));
				const mediaList = tempPlaylistMedias.map((eachMedia: any) => {
					return {
						order: eachMedia.order,
						item: {
							id: eachMedia.item.id,
						},
					};
				});

				const assetId = mediaDetails?.asset?.id;
				const playListId = mediaDetails.id;
				const mediaListObject = {
					asset: {
						id: assetId,
					},
					id: playListId,
					medias: mediaList,
					metadata: {
						title: playlistMetaData && playlistMetaData.title ? playlistMetaData.title : '',
						description: playlistMetaData && playlistMetaData.title ? playlistMetaData.title : '',
					},
				};
				dispatch(changeTheOrderOfSelectedMediaItemInPlaylist(defaultAccountId, playListId, mediaListObject))
					.then(() => {
						dispatch(
							showMessage(
								t(
									'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_LABEL_MEDIA_ORDER_CHANGED_NOTIFICATION_PLAYLIST'
								),
								messageTypes.success
							)
						);
					})
					.catch((e: any) => {
						setMedias(_.sortBy(medias, 'order'));
						dispatch(
							showMessage(
								t(
									'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_LABEL_MEDIA_ORDER_CHANGED_NOTIFICATION_PLAYLIST_FAILED'
								) + ` ${e}`,
								messageTypes.error
							)
						);
					});
			}
		},
		[defaultAccountId, dispatch, mediaDetails?.asset?.id, mediaDetails.id, medias, playlistMetaData, t]
	);

	const removeMediaItemFromThePlaylist = useCallback(
		(media: any) => {
			const playListId = mediaDetails.id;
			dispatch(removeSelectedMediaItemFromThePlaylist(defaultAccountId, playListId, media.id)).then(
				(data: any) => {
					if (data && !data.message && !data.Message) {
						setTimeout(() => {
							dispatch(
								showMessage(
									t('MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_LABEL_REMOVED_THE_MEDIA_ITEM_FROM_PLAYLIST'),
									messageTypes.success
								)
							);
							loadTheMediaItems();
						}, 2000);
					} else {
						dispatch(
							showMessage(
								t(
									'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_LABEL_REMOVED_THE_MEDIA_ITEM_FROM_PLAYLIST_FAILED'
								),
								messageTypes.error
							)
						);
					}
				}
			);
		},
		[defaultAccountId, dispatch, loadTheMediaItems, mediaDetails?.id, t]
	);

	const onMediaActionMenuClick = useCallback(
		(media: any, actionId: string) => {
			if (actionId !== mediaActionKeys.removeFromPlaylist || !media?.id) {
				return;
			}
			removeMediaItemFromThePlaylist(media);
		},
		[removeMediaItemFromThePlaylist]
	);

	useEffect(() => {
		loadTheMediaItems();
	}, [loadTheMediaItems]);

	const checkScrollBar = () => {
		if (!listMediaRef.current) {
			return;
		}

		const tableRef = listMediaRef.current.getElementsByTagName('tbody')[0];
		if (!tableRef) {
			return;
		}
		const tr = tableRef.getElementsByTagName('tr')[0];
		const trRect = tr?.getBoundingClientRect();
		const tbodyRect = tableRef.getBoundingClientRect();
		setScrollSize(tbodyRect.width - trRect.width);
	};

	useEffect(() => {
		window.addEventListener('resize', checkScrollBar);

		return () => window.removeEventListener('resize', checkScrollBar);
	}, []);

	return (
		<>
			<div className="list-media-display" ref={(r) => (listMediaRef.current = r)}>
				<table>
					<thead>
						<tr className="list-media-display__header">
							<th style={{ textAlign: 'center' }} className={`${mediaContainerBaseClassName}__index`}>
								#
							</th>
							<th className="media-item-container__main-tr"></th>
							<th colSpan={2}>
								<span>{t('COMMON_TITLE')}</span>
							</th>
							{!isMobileView && <th>{t('COMMON_DURATION')}</th>}
							{!isMobileView && <th>{t('COMMON_CREATED')}</th>}
							<th className={`${mediaContainerBaseClassName}__action`}></th>
							{scrollSize > 0 && <th style={{ width: `${scrollSize}px` }} />}
						</tr>
					</thead>
					{medias &&
						medias.filter(Boolean).length > 0 &&
						(!smartPlaylist ? (
							<DragDropContext onDragEnd={onDragEnd} onDragUpdate={() => {}}>
								<Droppable droppableId="droppable">
									{(provided) => (
										<tbody ref={provided.innerRef} {...provided.droppableProps}>
											{medias.map((media: any, index) => (
												<Draggable
													key={media.item.id}
													draggableId={media.item.id}
													index={index}
												>
													{(provided, snapshot) => (
														<MediaListItem
															itemRef={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={{
																background: snapshot.isDragging ? '#ffffff' : '#f5f5f5',
																...provided.draggableProps.style,
															}}
															isMobileView={isMobileView}
															key={media.item.id}
															media={media.item}
															index={index + 1}
															onMediaActionMenuClick={onMediaActionMenuClick}
														/>
													)}
												</Draggable>
											))}
										</tbody>
									)}
								</Droppable>
							</DragDropContext>
						) : (
							<tbody>
								{medias.map((media: any, index) => (
									<MediaListItem
										style={{
											background: '#f5f5f5',
										}}
										isMobileView={isMobileView}
										key={media.id}
										media={media}
										index={index + 1}
										onMediaActionMenuClick={onMediaActionMenuClick}
										noDeleteAction
									/>
								))}
							</tbody>
						))}
				</table>
			</div>
		</>
	);
};

const ShortcutMenuItems = [
	{
		text: 'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_GO_TO_INSPECT',
		icon: <Search />,
		value: mediaActionKeys.openMediaInNewWindow,
	},
	{
		text: 'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_REMOVE_FROM_PLAYLIST',
		icon: <DeleteOutlined />,
		value: mediaActionKeys.removeFromPlaylist,
	},
];

export interface MediaItemProps {
	itemRef?: any;
	isMiniLibrary?: boolean;
	noDeleteAction?: boolean;
	media?: any;
	isMobileView?: boolean;
	thumbnail?: any;
	index: number;
	style?: any;
	onMediaActionMenuClick?: (_mediaDetails: any, _action: string) => void; // params: mediaDetails, actionId
}

export const MediaListItem: React.FC<MediaItemProps> = ({
	media,
	isMobileView,
	thumbnail: thumbnailMedia,
	index,
	itemRef,
	onMediaActionMenuClick,
	noDeleteAction,
	...rest
}) => {
	const { t } = useTranslation();

	const [contextMenu, setContextMenu] = useState<any | undefined>(undefined);

	const mediaDetail = getDetails(media, false);

	const thumbnail = useMemo(() => {
		let thumbnailSource = mediaDetail;
		if (thumbnailMedia) {
			thumbnailSource = getDetails(thumbnailMedia, false);
		}

		if (!thumbnailSource) {
			return '';
		}

		let mediaThumbnail = thumbnailSource.thumbnail as any;
		if (mediaThumbnail instanceof Array) {
			mediaThumbnail = getLowQThumbnailFromRenditions(mediaThumbnail);
		}
		return mediaThumbnail;
	}, [mediaDetail, thumbnailMedia]);

	const getDuration = useCallback(() => {
		const resources = mediaDetail?.asset?.resources;
		if (!resources) {
			return '';
		}
		for (let eachResource in resources) {
			if (resources[eachResource].type == mediaTypes.video) {
				let renditions = resources[eachResource].renditions;
				if (renditions && renditions.length > 0) {
					for (let rendition in renditions) {
						let videos = renditions[rendition].videos;
						if (videos && videos.length > 0 && videos[0].duration) {
							return convertSecondsToTimeStamp(videos[0].duration);
						}
					}
				}
			}
		}
		return '';
	}, [mediaDetail]);

	const onOpenShortcutMenu = useCallback((e: React.MouseEvent<HTMLElement>, isMoreBtnClicked = false) => {
		e.stopPropagation();
		e.preventDefault();

		setContextMenu(
			isMoreBtnClicked
				? e.currentTarget
				: {
						left: e.clientX + 2,
						top: e.clientY - 6,
				  }
		);
	}, []);

	const onContextMenuItemClick = useCallback(
		(item: any) => {
			setContextMenu(null);
			switch (item.value) {
				case mediaActionKeys.openMediaInNewWindow:
					window.open(getInspectLink(mediaDetail), '_blank');
					break;
				case mediaActionKeys.removeFromPlaylist:
					onMediaActionMenuClick?.(mediaDetail, mediaActionKeys.removeFromPlaylist);
					break;
			}
		},
		[mediaDetail, onMediaActionMenuClick]
	);

	const onClickMediaItem = useCallback(() => {
		window.open(getInspectLink(mediaDetail), '_blank');
	}, [mediaDetail]);

	return mediaDetail ? (
		<>
			<tr
				className={mediaContainerBaseClassName}
				onContextMenu={onOpenShortcutMenu}
				ref={itemRef}
				{...rest}
				onClick={onClickMediaItem}
			>
				<td style={{ textAlign: 'center' }} className={`${mediaContainerBaseClassName}__index`}>
					<span className={`${mediaContainerBaseClassName}__title`}>{index}</span>
				</td>
				<td className="media-item-container__main-tr">
					<div className={`${mediaContainerBaseClassName}__main`}>
						<div
							className={`${mediaContainerBaseClassName}__thumbnail`}
							style={mediaDetail.type === mediaTypes.catalog ? { width: 'auto' } : undefined}
						>
							<img
								className={
									!thumbnail ||
									thumbnail === no_thumbnail ||
									thumbnail.toString().toLowerCase().includes('nothumbnail')
										? `${mediaContainerBaseClassName}__main-no-thumbnail`
										: ''
								}
								src={thumbnail === no_thumbnail ? no_thumbnail : thumbnail}
							/>
						</div>
					</div>
				</td>
				<td colSpan={2}>
					<span
						className={`${mediaContainerBaseClassName}__title`}
						title={mediaDetail.title || t('COMMON_NO_TITLE')}
						style={{ fontWeight: 'normal' }}
					>
						{mediaDetail.title || t('COMMON_NO_TITLE')}
					</span>
				</td>
				{!isMobileView && (
					<>
						<td>
							<span className={`${mediaContainerBaseClassName}__date`}>{getDuration()}</span>
						</td>
						<td>
							<span className={`${mediaContainerBaseClassName}__date`}>
								{mediaDetail &&
									dateToTimeElapsed(mediaDetail?.created || mediaDetail?.asset?.created, t as any)}
							</span>
						</td>
					</>
				)}
				<td style={{ textAlign: 'center' }} className={`${mediaContainerBaseClassName}__action`}>
					<IconButton
						style={{ width: '32px', height: '32px' }}
						title={t('MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_CLICK_TO_SEE_ACTION')}
						onClick={(e) => onOpenShortcutMenu(e, true)}
					>
						<MoreHoriz />
					</IconButton>
				</td>
			</tr>

			<Menu
				open={Boolean(contextMenu)}
				getContentAnchorEl={null}
				onClose={() => setContextMenu(null)}
				anchorReference={contextMenu?.top !== undefined ? 'anchorPosition' : undefined}
				anchorPosition={contextMenu}
				anchorEl={contextMenu?.top !== undefined ? undefined : contextMenu}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				{ShortcutMenuItems.map((item: any, i: number) => {
					if (noDeleteAction && item.value === mediaActionKeys.removeFromPlaylist) {
						return null;
					}
					return (
						<MenuItem key={i} onClick={() => onContextMenuItemClick(item)}>
							<ListItemIcon style={{ minWidth: '30px', ...(item.style ?? {}) }}>{item.icon}</ListItemIcon>
							<ListItemText primary={t(item.text)} />
						</MenuItem>
					);
				})}
			</Menu>
		</>
	) : (
		<></>
	);
};
