import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEncodingProfiles, loadUploadingFlows } from '../../../actions/publishActions';
import { mediaNamesQuery, mediaTypes, sortTypes } from '../../../constants/mediaConstants';
import { loadMediasPerNavigationWithTotalCount } from '../../../services/mediaService';
import { TAB_ID } from '../InteractMediaLibraryModal';
import debounce from 'lodash/debounce';
import { getStock } from '../../../services/thirdPartyService';
import { getDetails } from '../../../services/mediaDisplayService';

export const NO_CATALOGS_ID = 'nocatalog';

const useFetchData = (mediaType: string, searchKeyword: string, currentTab: string, isModalOpen: boolean) => {
	const dispatch = useDispatch<any>();

	const accountId = useSelector((state: any) => state.session.defaultAccountId);
	const refreshMedias = useSelector((state) => (state as any).publish.refreshMedias);

	const [encodingProfiles, setEncodingProfiles] = useState([]);
	const [qbrickEncoderFlows, setQbrickEncoderFlows] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [selectedCatalog, setSelectedCatalog] = useState(NO_CATALOGS_ID);

	const [medias, setMedias] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [selectedStockProvider, setSelectedStockProvider] = useState('Pexels');
	const [isFetchingData, setIsFetchingData] = useState(false);
	const [{ page, rowsPerPage }, setPaginationInfo] = useState({
		page: 0,
		rowsPerPage: 50,
	});

	const [stockMedias, setStockMedias] = useState([]);
	const fetchLibraryMedias = useCallback(
		debounce((mediaType, searchKeyword, page, rowsPerPage, selectedCatalog) => {
			let searchMediaQuery =
				mediaType === mediaTypes.image
					? `q=${searchKeyword}* AND ${mediaNamesQuery.image}`
					: searchKeyword === ''
					? `q=${mediaNamesQuery.allMediasWithoutImage}`
					: `q=${searchKeyword}*`;

			if (selectedCatalog !== NO_CATALOGS_ID) {
				searchMediaQuery = `${searchMediaQuery} AND catalog.id:${selectedCatalog}`;
			}

			loadMediasPerNavigationWithTotalCount({
				accountId,
				mediaPerPage: rowsPerPage,
				offset: page * rowsPerPage,
				mediaName: mediaNamesQuery.medias,
				sortType: sortTypes.newest,
				query: searchMediaQuery,
				signal: undefined,
			}).then((response: any = { data: [], count: 0 }) => {
				const { data, count } = response;
				setMedias(data.map((media: any) => getDetails(media)));
				setTotalCount(count);
				setIsFetchingData(false);
			});
		}, 500),
		[mediaType]
	);

	const fetchStocks = useCallback(
		debounce((mediaType, searchKeyword, page, rowsPerPage, selectedProvider) => {
			getStock(mediaType, searchKeyword, page, rowsPerPage, selectedProvider).then((data: any) => {
				setStockMedias(data.result);
				setTotalCount(data.count);
				setIsFetchingData(false);
			});
		}, 500),
		[mediaType]
	);

	useEffect(() => {
		dispatch(loadEncodingProfiles(accountId)).then((data: any) => {
			if (!data || !data.map) {
				return;
			}
			setEncodingProfiles(data);
		});
	}, []);

	useEffect(() => {
		dispatch(loadUploadingFlows(accountId)).then((data: any) => {
			if (!data || !data.flows || typeof data === 'string') {
				return;
			}
			setQbrickEncoderFlows(data.flows ?? []);
		});
	}, []);

	useEffect(() => {
		setPaginationInfo({ page: 0, rowsPerPage: 50 });
		setTotalCount(0);
	}, [mediaType, currentTab, searchKeyword]);

	useEffect(() => {
		if (currentTab !== TAB_ID.MEDIA_LIBRARY || !isModalOpen) {
			return;
		}

		setIsFetchingData(true);
		fetchLibraryMedias(mediaType, searchKeyword, page, rowsPerPage, selectedCatalog);
	}, [mediaType, currentTab, selectedCatalog, searchKeyword, page, rowsPerPage, isModalOpen, refreshMedias]);

	useEffect(() => {
		if (currentTab !== TAB_ID.STOCK_LIBRARY) {
			return;
		}
		setIsFetchingData(true);
		fetchStocks(mediaType, searchKeyword, page, rowsPerPage, selectedStockProvider);
	}, [mediaType, currentTab, selectedStockProvider, searchKeyword, page, rowsPerPage]);

	return {
		encodingProfiles,
		qbrickEncoderFlows,
		medias,
		totalCount,
		stockMedias,
		page,
		rowsPerPage,
		selectedCatalog,
		selectedStockProvider,
		isUploading,
		isFetchingData,
		setPaginationInfo,
		setSelectedCatalog,
		setSelectedStockProvider,
		setIsUploading,
	};
};
export default useFetchData;
