import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import LoadingOverlay from 'react-loading-overlay';
import { ScaleLoader } from 'react-spinners';
import { FormHelperText, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import _ from 'lodash';

import ColorPicker from '../../../../../reusable/ColorPicker/ColorPicker';
import ConfirmationDialog from '../../../../../reusable/ConfirmationDialog/ConfirmationDialog';
import edit from '../../../../../assets/images/edit_icon.svg';
import delete_icon from '../../../../../assets/images/delete.svg';
import star from '../../../../../assets/images/star.svg';
import star_select from '../../../../../assets/images/star_select.svg';
import chevron_down from '../../../../../assets/images/chevron-down.svg';
import right_arrow from '../../../../../assets/images/right_arrow.svg';
import phone_fill from '../../../../../assets/images/phone-fill.png';
import display_fill from '../../../../../assets/images/display-fill.png';
import lock_fill from '../../../../../assets/images/lock-fill.svg';
import no_medias_icon from '../../../../../assets/images/no_medias.svg';
import refresh from '../../../../../assets/images/arrow-counterclockwise.svg';

import {
	accessibilitySettingsKeys,
	advancedSettingLabels,
	advancedSettingTypes,
	bottomControlsKeys,
	defaultSettingTypes,
	engagementFeaturesKeys,
	fonts,
	headerWithCheckbox,
	opacityKeys,
	playbackOptionsKeys,
	playerStylingKeys,
	publicConfigIds,
	subDataKeys,
	topControlsKeys,
	analyticsSettingsKeys,
	advancedSettingTypesOrder,
	additionalSettingsKeys,
	advancedSettingsHelperTexts,
	playCoverOptionsKeys,
	PLAY_COVER_CONSTANTS,
	liveCountdownOptionsKeys,
	LIVE_COUNTDOWN_CONSTANTS_DESKTOP,
	LIVE_COUNTDOWN_CONSTANTS_MOBILE,
	solidBackgroundStylingKeys,
	controlPositions,
	SHARE_ORIGIN_ITEMS,
	COUNTDOWN_FONT_SIZES,
	subtitleStylingKeys,
} from '../../../../../constants/playerConfigurationsConstant';
import { a11yLanguages } from '../../../../../constants/languageConstants';
import { dialogSizes } from '../../../../../constants/dialogSizesConstant';
import {
	DEFAULT_THEO_PLAYER_EMBED,
	THEO_PLAYER_PRESET_ID,
	mediaNamesQuery,
	messageTypes,
	qbrickPlayerList,
	sortTypes,
} from '../../../../../constants/mediaConstants';
import { generateId } from '../../../../../services/stringHelperService';
import { updatePlayerSizeOfPlayerConfiguration } from '../../../../../services/inspectScreenHelperService';
import {
	addNewPlayerConfiguration,
	updatePlayerConfiguration,
	deletePlayerConfiguration,
	loadPlayerConfigurationById,
	loadPlayerConfiguration,
	getPublicPlayerConfigurations,
} from '../../../../../actions/playerConfigurationActions';
import { showMessage } from '../../../../../actions/globalActions';
import { loadMediasForPagination } from '../../../../../actions/publishActions';
import { getAccountSettings } from '../../../../../actions/profileActions';
import { joinClassNames } from '../../../../../services/elementHelperService';

import './PlayerConfigurations.scss';

class PlayerConfigurations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedSections: ['presets', 'advancedSettings'],
			expandedCards: [...Object.keys(advancedSettingTypes)],
			advancedSettings: [],
			activeLayout: 'desktop',
			selectedSetting: null,
			originSelectedSettingData: null,
			savedPresetEditNameValue: '',
			savedPresetEditing: null,
			savedPresetDeleting: null,
			showSaveAsPresetDialog: false,
			playerConfigurationId: qbrickPlayerList.qbrickPlayer,
			saveAsNewPreset: false,
			saveAsPresetDialogRecommendedValue: false,
			latestMedia: null,
			videoWidth: window.innerWidth,
			videoHeight: window.innerHeight,
			loading: true,
			showChangeSelectedSelectingConfirmDialog: false,
			defaultPlayerConfigs: [],
			publicConfigs: [],
			errorMessage: [],
			languageOfAccountName: 'all',
		};

		this.mediaDetailContainer = 'mediaDetailContainer-' + generateId();
		this.currentDesktopLayout = null;
		this.currentMobileLayout = null;
		this.inspectViewLivePlayerRef = null;
	}

	componentDidMount() {
		this.loadAccountPlayerConfiguration();
		this.loadLatestMedia();
		this.loadDefaultPlayerConfigs();
		updatePlayerSizeOfPlayerConfiguration(this);

		window.addEventListener('load', this.loadAccountPlayerConfiguration);
		window.addEventListener('resize', () => updatePlayerSizeOfPlayerConfiguration(this));

		this.initPlayer();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.defaultAccountId !== this.props.defaultAccountId) {
			this.loadAccountPlayerConfiguration();
			this.loadLatestMedia();
			this.loadDefaultPlayerConfigs();
		}
		if (prevState.selectedSetting?.id !== this.state.selectedSetting?.id) {
			window.QplayerLoader.killInteractivePlayer?.();

			setTimeout(() => updatePlayerSizeOfPlayerConfiguration(this), 100);
			const element = document.getElementById('player-preview');
			if (element) {
				element.innerHTML = '';
			}
			this.initPlayer();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('load', this.loadAccountPlayerConfiguration);
		window.removeEventListener('resize', () => updatePlayerSizeOfPlayerConfiguration(this));
		window.QplayerLoader.killInteractivePlayer?.();
	}

	initPlayer = () => {
		const { selectedSetting } = this.state;
		const isUsingTheoPlayer = selectedSetting && selectedSetting.id === THEO_PLAYER_PRESET_ID;

		if (
			!isUsingTheoPlayer &&
			window.QplayerLoader.initiateInteractivePlayer &&
			typeof window.QplayerLoader.initiateInteractivePlayer === 'function'
		) {
			window.QplayerLoader.initiateInteractivePlayer();
		}
	};

	loadLatestMedia = () => {
		const { t } = this.props;
		let query = mediaNamesQuery.medias + mediaNamesQuery.video;
		this.props.loadMediasForPagination(this.props.defaultAccountId, 1, 0, query, sortTypes.newest).then((media) => {
			if (media && media.map && media.length > 0) {
				this.setState({ latestMedia: media[0].id });
			} else {
				this.props.showMessage(t('SETTINGS_PRESET_CONFIG_UPLOAD_VIDEO_MESSAGE'), messageTypes.info);
			}
		});
	};

	loadAccountPlayerConfiguration = (reset = true, callback) => {
		setTimeout(() => {
			const { defaultAccountId, showMessage, t } = this.props;

			this.props.getPublicPlayerConfigurations().then((data) => {
				if (!data) {
					return;
				}

				this.setState({ publicConfigs: data });
			});

			this.props
				.loadPlayerConfiguration(defaultAccountId)
				.then((data) => {
					if (!data || (data && data.message)) {
						showMessage(t('SETTINGS_PRESET_CONFIG_LOAD_DATA_FAIL'), messageTypes.error);
						this.setState({
							loading: false,
							advancedSettings: [],
							selectedSetting: null,
							originSelectedSettingData: null,
						});
						return;
					}

					const activeLayout = reset ? 'desktop' : this.state.activeLayout;
					const selectedSetting = data.find((d) => d.recommended) ?? {};

					const currentLayout = selectedSetting?.layouts?.find((l) => l.type === activeLayout) ?? {};
					const languageOfAccountName =
						!currentLayout?.topControls?.accountName ||
						typeof currentLayout?.topControls?.accountName === 'string'
							? 'all'
							: Object.keys(currentLayout?.topControls?.accountName)[0];
					this.setState(
						(prevState) => ({
							selectedSetting: reset ? selectedSetting : prevState.selectedSetting,
							advancedSettings: [...data].filter((c) => c.id !== 'Shopping'),
							activeLayout,
							originSelectedSettingData: JSON.parse(
								JSON.stringify(reset ? selectedSetting : prevState.selectedSetting)
							),
							loading: false,
							languageOfAccountName,
						}),
						() => callback && callback()
					);
				})
				.catch((error) => {
					showMessage(`${t('SETTINGS_PRESET_CONFIG_LOAD_DATA_FAIL')}. ${error}`, messageTypes.error);
					this.setState({
						loading: false,
						advancedSettings: [],
						selectedSetting: null,
						originSelectedSettingData: null,
					});
				});
		}, 300);
	};

	loadDefaultPlayerConfigs = () => {
		const { defaultAccountId, getAccountSettings } = this.props;
		getAccountSettings(defaultAccountId).then((settings) => {
			if (!settings || settings.length === 0) {
				return;
			}

			let defaultPlayerConfigs = [];
			if (
				settings.hasOwnProperty('qbrickPlayer') &&
				settings['qbrickPlayer'] &&
				settings['qbrickPlayer'].length > 0
			) {
				defaultPlayerConfigs = settings['qbrickPlayer'].find((v) => v.version === '2')?.presets?.defaults;
			}
			this.setState({ defaultPlayerConfigs: defaultPlayerConfigs });
		});
	};

	expandSubHeader = (section) => {
		if (this.state.expandedSections.includes(section)) {
			this.setState((prevState) => ({
				expandedSections: prevState.expandedSections.filter((s) => s !== section),
			}));
		} else {
			this.setState((prevState) => ({
				expandedSections: [...prevState.expandedSections, section],
			}));
		}
	};

	expandCard = (cardID, e) => {
		const nodeName = e.target.nodeName.toLowerCase();
		if (nodeName === 'span' || nodeName === 'input') {
			return;
		}

		if (this.state.expandedCards.includes(cardID)) {
			this.setState((prevState) => ({
				expandedCards: prevState.expandedCards.filter((s) => s !== cardID),
			}));
		} else {
			this.setState((prevState) => ({
				expandedCards: [...prevState.expandedCards, cardID],
			}));
		}
	};

	changeData = (settingKey, dataKey, value) => {
		const { selectedSetting, activeLayout } = this.state;

		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = (newSelectedSetting.layouts ?? []).find((layout) => layout.type !== activeLayout);
		const newLayoutData = (newSelectedSetting.layouts ?? []).find((layout) => layout.type === activeLayout);

		if (newLayoutData[settingKey]) {
			newLayoutData[settingKey][dataKey] = value;
		} else {
			newLayoutData[settingKey] = {
				[dataKey]: value,
			};
		}

		newSelectedSetting.layouts = [otherLayout, newLayoutData];

		return newSelectedSetting;
	};

	resetToDefault = (e, settingKey) => {
		e.stopPropagation();
		const { selectedSetting, activeLayout, publicConfigs, defaultPlayerConfigs } = this.state;
		if (!selectedSetting || !defaultPlayerConfigs.includes(selectedSetting.id)) {
			return;
		}

		const publicConfig = publicConfigs.find((c) => c.id === publicConfigIds[selectedSetting.id]);
		const defaultLayout = publicConfig?.layouts?.find((l) => l.type === activeLayout) ?? {};
		const revertedSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = revertedSelectedSetting.layouts?.find((layout) => layout.type !== activeLayout);
		const newLayoutData = revertedSelectedSetting.layouts?.find((layout) => layout.type === activeLayout);

		newLayoutData[settingKey] = defaultLayout[settingKey];
		revertedSelectedSetting.layouts = [otherLayout, newLayoutData];

		this.setState({ selectedSetting: revertedSelectedSetting });
	};

	setActiveLayout = (layout) => {
		if (this.state.activeLayout === layout) {
			return;
		}
		this.setState({ activeLayout: layout });
	};

	handleA11yLangChange = (event) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeData('accessibilitySettings', 'language', event.target.value);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleMobilePlayPausePositionChange = (event) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeData('bottomControls', 'playPausePosition', event.target.value);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handlePlayerCustomFontUrlChange = (event, controlData) => {
		if (!this.state.selectedSetting) {
			return;
		}

		let errorMessage = this.state.errorMessage;
		if (controlData['useCustomFont']) {
			if (event.target.value === '' && !errorMessage.includes('playerCustomFontUrl')) {
				errorMessage.push('playerCustomFontUrl');
			}
			if (event.target.value !== '' && errorMessage.includes('playerCustomFontUrl')) {
				errorMessage = errorMessage.filter((m) => m !== 'playerCustomFontUrl');
			}
		}

		const newSelectedSetting = this.changeData('playerStyling', 'playerCustomFontUrl', event.target.value);
		this.setState({
			selectedSetting: newSelectedSetting,
			errorMessage: errorMessage,
		});
	};

	handlePlayerCustomFontNameChange = (event, controlData) => {
		if (!this.state.selectedSetting) {
			return;
		}

		let errorMessage = this.state.errorMessage;
		if (controlData['useCustomFont']) {
			if (event.target.value === '' && !errorMessage.includes('playerCustomFontName')) {
				errorMessage.push('playerCustomFontName');
			}
			if (event.target.value !== '' && errorMessage.includes('playerCustomFontName')) {
				errorMessage = errorMessage.filter((m) => m !== 'playerCustomFontName');
			}
		}

		const newSelectedSetting = this.changeData('playerStyling', 'playerCustomFontName', event.target.value);
		this.setState({
			selectedSetting: newSelectedSetting,
			errorMessage: errorMessage,
		});
	};

	handleShareOriginSelectChange = (event) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const value = event.target.value;
		const { selectedSetting, activeLayout } = this.state;

		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
		const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);

		if (newLayoutData.topControls.shareSettings?.urlOrigin) {
			newLayoutData.topControls['shareSettings']['urlOrigin'] = value;
		} else {
			const oldSettings = newLayoutData.topControls['shareSettings'] ?? {};
			newLayoutData.topControls['shareSettings'] = {
				...oldSettings,
				urlOrigin: value,
			};
		}

		newSelectedSetting.layouts = [otherLayout, newLayoutData];

		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleShareUrlInputChange = (event) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const value = event.target.value;
		const { selectedSetting, activeLayout } = this.state;

		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
		const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);

		if (newLayoutData.topControls.shareSettings?.customUrl) {
			newLayoutData.topControls['shareSettings']['customUrl'] = value;
		} else {
			const oldSettings = newLayoutData.topControls['shareSettings'] ?? {};
			newLayoutData.topControls['shareSettings'] = {
				...oldSettings,
				customUrl: value,
			};
		}

		newSelectedSetting.layouts = [otherLayout, newLayoutData];

		this.setState({ selectedSetting: newSelectedSetting });
	};

	handlePlayerFontChange = (event) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeData('playerStyling', 'playerFont', event.target.value);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleLanguageOfAccountNameChange = (event) => {
		this.setState({ languageOfAccountName: event.target.value });
	};

	handleAccountNameChange = (event) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const { selectedSetting, activeLayout, languageOfAccountName } = this.state;
		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
		const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);

		const newValue = { [languageOfAccountName]: event.target.value };
		if (newLayoutData.topControls) {
			const prevAccName =
				!newLayoutData.topControls.accountName || typeof newLayoutData.topControls.accountName === 'string'
					? { all: newLayoutData.topControls.accountName ?? '' }
					: newLayoutData.topControls.accountName ?? {};
			newLayoutData.topControls.accountName = { ...prevAccName, ...newValue };
		} else {
			newLayoutData[settingKey] = {
				[dataKey]: newValue,
			};
		}

		newSelectedSetting.layouts = [otherLayout, newLayoutData];
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleTopControlsInputChange = (event, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeData('topControls', dataKey, event.target.value);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handlePlayerNameChange = (event) => {
		const { selectedSetting } = this.state;
		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		newSelectedSetting.name = event.target.value;
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleCheckboxChange = (checked, settingKey, dataKey, controlData) => {
		if (!this.state.selectedSetting) {
			return;
		}

		let errorMessage = [];
		if (dataKey === 'useCustomFont' && checked && controlData) {
			if (!controlData.playerCustomFontName) {
				errorMessage.push('playerCustomFontName');
			}
			if (!controlData.playerCustomFontUrl) {
				errorMessage.push('playerCustomFontUrl');
			}
		}
		const newSelectedSetting = this.changeData(settingKey, dataKey, checked);
		this.setState({ selectedSetting: newSelectedSetting, errorMessage: errorMessage });
	};

	handleColorPickerChange = (event, settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeData(settingKey, dataKey, event.hex);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleOpacityChange = (event, settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeData(
			settingKey,
			opacityKeys[dataKey],
			event.target.value > 100 ? 100 : event.target.value
		);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleGAMeasurementIdChange = (event, settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeData(settingKey, analyticsSettingsKeys[dataKey], event.target.value);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleSubtitlePositionChange = (value, _settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		if (isNaN(value) || value > 100 || value < 0) {
			return;
		}

		const { selectedSetting, activeLayout } = this.state;
		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
		const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);

		if (newLayoutData['subtitleStyling'] && newLayoutData['subtitleStyling']['position']) {
			const oldPosition = newLayoutData['subtitleStyling']['position'] ?? {};
			newLayoutData['subtitleStyling']['position'] = {
				...oldPosition,
				[dataKey]: value,
			};
		} else {
			newLayoutData['subtitleStyling'] = {
				position: {
					[dataKey]: value,
				},
			};
		}

		newSelectedSetting.layouts = [otherLayout, newLayoutData];

		this.setState({ selectedSetting: newSelectedSetting });
	};

	handlePlayerCoverPropsChange = (value, controlData, settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const { selectedSetting, activeLayout } = this.state;

		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
		const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);
		let updatedValue = value;

		if (dataKey !== 'customPlayButtonUrl' && isNaN(updatedValue)) {
			return;
		}

		if (dataKey === 'playButtonPositionX' || dataKey === 'playButtonPositionY') {
			if (updatedValue > 100 || updatedValue < 0) {
				return;
			}
		}

		if (!controlData || Object.keys(controlData).length == 0) {
			const posterObj = {
				...PLAY_COVER_CONSTANTS,
				[dataKey]: updatedValue,
			};
			newLayoutData[settingKey] = posterObj;
			newSelectedSetting.layouts = [otherLayout, newLayoutData];
		} else {
			newLayoutData[settingKey][dataKey] = updatedValue;
		}

		this.setState({ selectedSetting: newSelectedSetting });
	};

	handlePlayerDefaultVolumechange = (event, settingKey, dataKey) => {
		const value = event.target.value;
		if (!this.state.selectedSetting || value === '' || value > 100 || value < 0) {
			return;
		}

		const newSelectedSetting = this.changeData(settingKey, dataKey, Number(event.target.value));
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleDeleteSavedPreset = (event) => {
		if (!event.ok) {
			this.setState({ savedPresetDeleting: null });
			return;
		}

		const { defaultAccountId, deletePlayerConfiguration, showMessage, t } = this.props;
		const { savedPresetDeleting } = this.state;
		const idSavedPresetDeletingRecommended = savedPresetDeleting.recommended;
		const savedPresetDeletingId = savedPresetDeleting.id;

		deletePlayerConfiguration(defaultAccountId, savedPresetDeletingId).then((data) => {
			if (!data || (data && data.message)) {
				showMessage(t('SETTINGS_PRESET_CONFIG_DELETE_PLAYER_CONFIG_FAIL'), messageTypes.error);
				return;
			}

			showMessage(t('SETTINGS_PRESET_CONFIG_DELETE_PLAYER_CONFIG_SUCCESS'), messageTypes.info);
			if (idSavedPresetDeletingRecommended) {
				this.setFavoriteToDefaultSetting();
			}

			this.setState(
				(prevState) => ({
					savedPresetDeleting: null,
					activeLayout:
						prevState.selectedSetting && prevState.selectedSetting.id === savedPresetDeletingId
							? 'desktop'
							: prevState.activeLayout,
				}),
				() =>
					this.loadAccountPlayerConfiguration(
						this.state.selectedSetting && this.state.selectedSetting.id === savedPresetDeletingId
					)
			);
		});
	};

	setRecommendedSetting = (e, config) => {
		e.preventDefault();
		e.stopPropagation();

		const { advancedSettings } = this.state;
		const { showMessage, t } = this.props;
		const prevRecommended = advancedSettings.find((s) => s.recommended && s.id !== config.id);
		const newRecommended = !config.recommended;

		this.props
			.updatePlayerConfiguration(this.props.defaultAccountId, config.id, {
				...config,
				recommended: newRecommended,
			})
			.then((data) => {
				if (!data || (data && data.message)) {
					showMessage(t('SETTINGS_PRESET_CONFIG_SET_PLAYER_CONFIG_TO_FAVORITE_FAIL'), messageTypes.error);
					return;
				}

				showMessage(t('SETTINGS_PRESET_CONFIG_SET_PLAYER_CONFIG_TO_FAVORITE_SUCCESS'), messageTypes.success);
				if (newRecommended && prevRecommended) {
					this.uncheckFavoritePlayerConfig(prevRecommended);
				} else if (!prevRecommended && !newRecommended) {
					this.setFavoriteToDefaultSetting();
				}

				this.loadAccountPlayerConfiguration(false);
			});
	};

	selectSetting = (playerConfig) => {
		this.setState((prevState) => {
			const isEditing = !_.isEqual(prevState.selectedSetting, prevState.originSelectedSettingData);
			if (!prevState.selectedSetting || prevState.selectedSetting.id !== playerConfig.id) {
				if (isEditing) {
					return { showChangeSelectedSelectingConfirmDialog: playerConfig };
				}

				return {
					selectedSetting: playerConfig,
					originSelectedSettingData: JSON.parse(JSON.stringify(playerConfig)),
					activeLayout: 'desktop',
				};
			}
			return null;
		});
	};

	handleOnSelectSavedPreset = (event) => {
		const id = event.target.value;
		const newSelectedSetting = this.state.advancedSettings.find((setting) => setting.id === id);
		this.selectSetting(newSelectedSetting);
	};

	handleEditSavedPresetNameClick = (event, config) => {
		event.stopPropagation();
		this.setState({
			savedPresetEditNameValue: config.name,
			savedPresetEditing: config,
			saveAsPresetDialogRecommendedValue: config.recommended,
		});
	};

	handleDeleteSavedPresetNameClick = (event, config) => {
		event.stopPropagation();
		this.setState({
			savedPresetDeleting: config,
		});
	};

	handleSavedPresetNameValueChange = (event) => {
		this.setState({ savedPresetEditNameValue: event.target.value });
	};

	handleSavePresetModalClick = () => {
		if (this.state.showSaveAsPresetDialog) {
			this.saveAsNewPreset();
			return;
		}

		if (this.state.savedPresetEditing) {
			const { defaultAccountId, showMessage, t } = this.props;
			const {
				savedPresetEditNameValue,
				saveAsPresetDialogRecommendedValue,
				savedPresetEditing,
				advancedSettings,
			} = this.state;

			this.props
				.updatePlayerConfiguration(defaultAccountId, savedPresetEditing.id, {
					...savedPresetEditing,
					name: savedPresetEditNameValue,
					recommended: !!saveAsPresetDialogRecommendedValue,
				})
				.then((data) => {
					if (!data || (data && data.message)) {
						showMessage(t('SETTINGS_PRESET_CONFIG_UPDATE_PLAYER_CONFIG_FAIL'), messageTypes.error);
						return;
					}

					showMessage(t('SETTINGS_PRESET_CONFIG_UPDATE_PLAYER_CONFIG_SUCCESS'), messageTypes.success);
					advancedSettings.map((s) => (s.id === savedPresetEditing.id ? savedPresetEditing : s));
					if (saveAsPresetDialogRecommendedValue) {
						this.uncheckFavoritePlayerConfig(
							advancedSettings.find((s) => s.recommended && s.id !== savedPresetEditing.id)
						);
					} else if (!advancedSettings.find((s) => s.recommended)) {
						this.setFavoriteToDefaultSetting();
					}

					this.setState(
						{
							savedPresetEditing: null,
						},
						() => this.loadAccountPlayerConfiguration(false)
					);
				});
		}
	};

	saveAsNewPreset = () => {
		const { defaultAccountId, addNewPlayerConfiguration, showMessage, t } = this.props;
		const { advancedSettings, savedPresetEditNameValue, selectedSetting, saveAsPresetDialogRecommendedValue } =
			this.state;
		if (!selectedSetting) {
			return;
		}

		const newPlayerConfigId = generateId();
		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		newSelectedSetting.id = newPlayerConfigId;
		newSelectedSetting.name = savedPresetEditNameValue;
		newSelectedSetting.recommended = !!saveAsPresetDialogRecommendedValue;

		addNewPlayerConfiguration(defaultAccountId, newSelectedSetting).then((data) => {
			if (!data || (data && data.message)) {
				showMessage(t('SETTINGS_PRESET_CONFIG_ADD_NEW_PRESET_FAIL'), messageTypes.error);
				return;
			}

			if (saveAsPresetDialogRecommendedValue) {
				this.uncheckFavoritePlayerConfig(
					advancedSettings.find((s) => s.recommended && s.id !== newPlayerConfigId)
				);
			}

			showMessage(t('SETTINGS_PRESET_CONFIG_ADD_NEW_PRESET_SUCCESS'), messageTypes.success);
			this.setState(
				{
					showSaveAsPresetDialog: false,
					selectedSetting: newSelectedSetting,
				},
				() => this.loadAccountPlayerConfiguration(false)
			);
		});
	};

	uncheckFavoritePlayerConfig = (config) => {
		if (!config) {
			return;
		}

		const { defaultAccountId, showMessage, t } = this.props;
		config.recommended = false;

		this.props
			.updatePlayerConfiguration(defaultAccountId, config.id, { ...config, recommended: false })
			.then((data) => {
				if (!data || (data && data.message)) {
					showMessage(t('SET_PLAYER_CONFIG_TO_FAVORITE_FAIL'), messageTypes.error);
					return;
				}
			});
	};

	setFavoriteToDefaultSetting = () => {
		const enterprise = this.state.advancedSettings.find((s) => s.id === 'Enterprise');
		if (!enterprise) {
			return;
		}

		const { defaultAccountId, showMessage, t } = this.props;

		this.props
			.updatePlayerConfiguration(defaultAccountId, enterprise.id, { ...enterprise, recommended: true })
			.then((data) => {
				if (!data || (data && data.message)) {
					showMessage(
						t('SETTINGS_PRESET_CONFIG_SET_DEFAULT_PLAYER_CONFIG_TO_FAVORITE_FAIL'),
						messageTypes.error
					);
					return;
				}

				showMessage(
					t('SETTINGS_PRESET_CONFIG_SET_DEFAULT_PLAYER_CONFIG_TO_FAVORITE_SUCCESS'),
					messageTypes.success
				);
			});
	};

	handleSaveAsNewPresetClick = () => {
		const { showMessage, t } = this.props;
		if (this.state.errorMessage.length > 0) {
			showMessage(
				`${t('SETTINGS_PRESET_CONFIG_UPDATE_PLAYER_CONFIG_FAIL')} ${t(
					'SETTINGS_PRESET_CONFIG_CUSTOM_FONT_ERROR'
				)}`,
				messageTypes.error
			);
			return;
		}

		const saveAsPresetDialogRecommendedValue = this.state.selectedSetting?.recommended;
		this.setState({
			showSaveAsPresetDialog: true,
			saveAsPresetDialogRecommendedValue: saveAsPresetDialogRecommendedValue,
			savedPresetEditNameValue: this.state.selectedSetting?.name,
		});
	};

	handleSavePlayerSetting = () => {
		const { defaultAccountId, showMessage, t } = this.props;
		const { advancedSettings, selectedSetting, errorMessage } = this.state;
		if (!selectedSetting) {
			return;
		}

		if (errorMessage.length > 0) {
			showMessage(
				`${t('SETTINGS_PRESET_CONFIG_UPDATE_PLAYER_CONFIG_FAIL')} ${t(
					'SETTINGS_PRESET_CONFIG_CUSTOM_FONT_ERROR'
				)}`,
				messageTypes.error
			);
			return;
		}

		this.props.updatePlayerConfiguration(defaultAccountId, selectedSetting.id, selectedSetting).then((data) => {
			if (data && data.message) {
				showMessage(t('SETTINGS_PRESET_CONFIG_UPDATE_PLAYER_CONFIG_FAIL'), messageTypes.error);
				return;
			}

			showMessage(t('SETTINGS_PRESET_CONFIG_UPDATE_PLAYER_CONFIG_SUCCESS'), messageTypes.success);

			if (selectedSetting.recommended) {
				this.uncheckFavoritePlayerConfig(
					advancedSettings.find((s) => s.recommended && s.id !== selectedSetting.id)
				);
			} else if (!advancedSettings.find((s) => s.recommended)) {
				this.setFavoriteToDefaultSetting();
			}

			this.setState(
				{
					savedPresetEditing: null,
				},
				() => this.loadAccountPlayerConfiguration(false)
			);
		});

		window.QplayerLoader.killInteractivePlayer?.();
		setTimeout(() => updatePlayerSizeOfPlayerConfiguration(this), 100);
		const element = document.getElementById('player-preview');
		if (element) {
			element.innerHTML = '';
		}
		this.initPlayer();
	};

	handleLiveCountdownPropsChange = (event, settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const { selectedSetting, activeLayout } = this.state;
		let newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));

		if (dataKey === 'customBackground') {
			const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
			const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);

			if (newLayoutData[settingKey]) {
				const existedData = newLayoutData[settingKey][dataKey] ?? {};
				newLayoutData[settingKey][dataKey] = {
					...existedData,
					url: event.target.value,
				};
			} else {
				newLayoutData[settingKey] = {
					customBackground: {
						url: event.target.value,
					},
				};
			}

			newSelectedSetting.layouts = [otherLayout, newLayoutData];
		} else {
			newSelectedSetting = this.changeData(settingKey, dataKey, event.target.value);
		}
		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleLiveCountdownPositionPropsChange = (value, controlData, settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const { selectedSetting, activeLayout } = this.state;

		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
		const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);
		let updatedValue = value;
		let liveCountdown = newLayoutData['liveCountdown'] ?? {};

		const defaultConfig =
			activeLayout === 'desktop' ? LIVE_COUNTDOWN_CONSTANTS_DESKTOP : LIVE_COUNTDOWN_CONSTANTS_MOBILE;

		if (!controlData || Object.keys(controlData).length == 0) {
			const newObj = {
				...defaultConfig[settingKey],
				[dataKey]: updatedValue,
			};
			liveCountdown[settingKey] = newObj;
		} else {
			const existedData = liveCountdown[settingKey] ?? {};
			liveCountdown[settingKey] = {
				...defaultConfig[settingKey],
				...existedData,
				[dataKey]: updatedValue,
			};
		}
		newLayoutData['liveCountdown'] = liveCountdown;
		newSelectedSetting.layouts = [otherLayout, newLayoutData];

		this.setState({ selectedSetting: newSelectedSetting });
	};

	handleLiveCountdownCheckboxChange = (checked, settingKey, dataKey) => {
		if (!this.state.selectedSetting) {
			return;
		}

		const newSelectedSetting = this.changeLiveCountdownCheckboxData(settingKey, dataKey, checked);
		this.setState({ selectedSetting: newSelectedSetting });
	};

	changeLiveCountdownCheckboxData = (settingKey, dataKey, value) => {
		const { selectedSetting, activeLayout } = this.state;

		const newSelectedSetting = JSON.parse(JSON.stringify(selectedSetting));
		const otherLayout = newSelectedSetting.layouts.find((layout) => layout.type !== activeLayout);
		const newLayoutData = newSelectedSetting.layouts.find((layout) => layout.type === activeLayout);

		if (newLayoutData[settingKey]) {
			if (newLayoutData[settingKey]) {
				const exitedData = newLayoutData[settingKey][dataKey] ?? {};
				newLayoutData[settingKey][dataKey] = { ...exitedData, enabled: value };
			} else {
				newLayoutData[settingKey] = {
					[dataKey]: { enabled: value },
				};
			}
		} else {
			const newData = {
				[dataKey]: { enabled: value },
			};
			newLayoutData[settingKey] = newData;
		}

		newSelectedSetting.layouts = [otherLayout, newLayoutData];
		return newSelectedSetting;
	};

	renderFormControlLiveCountdownCheckbox = (controlData, settingKey, dataKey, style) => {
		const enabled = !!controlData?.[dataKey]?.enabled;
		return (
			<FormControl>
				<FormControlLabel
					control={<Checkbox style={{ color: '#126CFC' }} name="4" checked={enabled} />}
					label={this.props.t(advancedSettingLabels[dataKey])}
					onChange={(_e, checked) => this.handleLiveCountdownCheckboxChange(checked, settingKey, dataKey)}
					style={style}
				/>
				<FormHelperText>{this.props.t(advancedSettingsHelperTexts[dataKey])}</FormHelperText>
			</FormControl>
		);
	};

	renderFormControlCheckbox = (controlData, settingKey, dataKey, style, enabledParam) => {
		if (dataKey === 'maintainProportions' && this.state.activeLayout === 'desktop') {
			return null;
		}

		const enabled =
			enabledParam !== undefined
				? enabledParam
				: headerWithCheckbox[settingKey]
				? !!controlData.enabled
				: undefined;

		const checkedState =
			dataKey === 'showUnmuteBtn' && controlData[dataKey] === undefined ? true : !!controlData[dataKey];

		return (
			<FormControl>
				<FormControlLabel
					control={
						<Checkbox
							style={enabled !== false ? { color: '#126CFC' } : undefined}
							name="4"
							checked={checkedState}
						/>
					}
					label={this.props.t(advancedSettingLabels[dataKey])}
					onChange={(e, checked) => this.handleCheckboxChange(checked, settingKey, dataKey, controlData)}
					style={style}
					disabled={enabled === false}
				/>
				<FormHelperText>{this.props.t(advancedSettingsHelperTexts[dataKey])}</FormHelperText>
			</FormControl>
		);
	};

	renderAdvancedSettings = () => {
		const {
			expandedSections,
			expandedCards,
			activeLayout,
			publicConfigs,
			defaultPlayerConfigs,
			errorMessage,
			languageOfAccountName,
		} = this.state;
		const { t } = this.props;

		const isAdvancedSettingsExpanded = expandedSections.includes('advancedSettings');
		let selectedSetting =
			this.state.selectedSetting &&
			this.state.selectedSetting.layouts &&
			this.state.selectedSetting.layouts.find((layout) => layout.type === activeLayout);

		// populate subtitleStyling to preset since this property is added later
		selectedSetting =
			(!selectedSetting?.subtitleStyling || !selectedSetting?.subtitleStyling?.position
				? {
						...(selectedSetting ?? {}),
						[advancedSettingTypes.subtitleStyling]: {
							position: {
								x: 0,
								y: 0,
							},
						},
				  }
				: selectedSetting) ?? {};

		// populate analytic setting to preset since this property is added later
		selectedSetting =
			(!selectedSetting?.analyticsSettings || !selectedSetting?.analyticsSettings?.gaMeasurementId
				? {
						...(selectedSetting ?? {}),
						[advancedSettingTypes.analyticsSettings]: {
							gaMeasurementId: '',
						},
				  }
				: selectedSetting) ?? {};

		const isDefaultPlayer =
			this.state.selectedSetting && defaultPlayerConfigs.includes(this.state.selectedSetting.id);

		const defaultCountdownSetting =
			activeLayout === 'mobile' ? LIVE_COUNTDOWN_CONSTANTS_MOBILE : LIVE_COUNTDOWN_CONSTANTS_DESKTOP;

		// populate additionalSettings to preset since this property is added later
		selectedSetting = !selectedSetting?.liveCountdown
			? {
					...(selectedSetting ?? {}),
					[advancedSettingTypes.liveCountdown]: defaultCountdownSetting,
			  }
			: {
					...(selectedSetting ?? {}),
					[advancedSettingTypes.liveCountdown]: {
						coverStyle: selectedSetting[advancedSettingTypes.liveCountdown].coverStyle ?? 'default',
						customBackground: {
							enabled:
								selectedSetting[advancedSettingTypes.liveCountdown].customBackground?.enabled ??
								defaultCountdownSetting.customBackground.enabled,
							url:
								selectedSetting[advancedSettingTypes.liveCountdown].customBackground?.url ??
								defaultCountdownSetting.customBackground.url,
						},
						titlePosition: {
							enabled:
								selectedSetting[advancedSettingTypes.liveCountdown].titlePosition?.enabled ??
								defaultCountdownSetting.titlePosition.enabled,
							x:
								selectedSetting[advancedSettingTypes.liveCountdown].titlePosition?.x ??
								defaultCountdownSetting.titlePosition.x,
							y:
								selectedSetting[advancedSettingTypes.liveCountdown].titlePosition?.y ??
								defaultCountdownSetting.titlePosition.y,
							fontsize:
								selectedSetting[advancedSettingTypes.liveCountdown].titlePosition?.fontsize ??
								defaultCountdownSetting.titlePosition.fontsize,
						},
						eventStartPosition: {
							enabled:
								selectedSetting[advancedSettingTypes.liveCountdown].eventStartPosition?.enabled ??
								defaultCountdownSetting.eventStartPosition.enabled,
							x:
								selectedSetting[advancedSettingTypes.liveCountdown].eventStartPosition?.x ??
								defaultCountdownSetting.eventStartPosition.x,
							y:
								selectedSetting[advancedSettingTypes.liveCountdown].eventStartPosition?.y ??
								defaultCountdownSetting.eventStartPosition.y,
							fontsize:
								selectedSetting[advancedSettingTypes.liveCountdown].eventStartPosition?.fontsize ??
								defaultCountdownSetting.eventStartPosition.fontsize,
						},
						countdownPosition: {
							enabled:
								selectedSetting[advancedSettingTypes.liveCountdown].countdownPosition?.enabled ??
								defaultCountdownSetting.countdownPosition.enabled,
							x:
								selectedSetting[advancedSettingTypes.liveCountdown].countdownPosition?.x ??
								defaultCountdownSetting.countdownPosition.x,
							y:
								selectedSetting[advancedSettingTypes.liveCountdown].countdownPosition?.y ??
								defaultCountdownSetting.countdownPosition.y,
							fontsize:
								selectedSetting[advancedSettingTypes.liveCountdown].countdownPosition?.fontsize ??
								defaultCountdownSetting.countdownPosition.fontsize,
						},
					},
			  };

		return (
			<Box>
				<div className={'playerConfigurations-subheader playerConfigurations-subheader--readonly'}>
					<span>
						{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_OPTION_HEADER')}
						<i>{`${this.state.selectedSetting.name}`}</i>
					</span>
				</div>

				{isAdvancedSettingsExpanded && (
					<div className="playerConfigurations-preset-name">
						<label htmlFor="name-of-preset">Name of preset</label>
						<div className="playerConfigurations-preset-name__input">
							<input
								disabled={isDefaultPlayer}
								id="name-of-preset"
								value={this.state.selectedSetting ? this.state.selectedSetting.name : ''}
								onChange={this.handlePlayerNameChange}
							/>
							{isDefaultPlayer && (
								<div className="playerConfigurations_tooltip-container">
									<label className="tooltiptext">
										{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_PRESET_NAME_LOCK')}
									</label>
									<img src={lock_fill} />
								</div>
							)}
						</div>
					</div>
				)}

				{isAdvancedSettingsExpanded && (
					<>
						<div className="playerConfigurations-devices">
							<button
								type="button"
								className={activeLayout === 'desktop' ? 'playerConfigurations-devices--selected' : ''}
								onClick={() => this.setActiveLayout('desktop')}
							>
								<img src={display_fill} /> {t('COMMON_DESKTOP')}
							</button>
							<button
								type="button"
								className={activeLayout === 'mobile' ? 'playerConfigurations-devices--selected' : ''}
								onClick={() => this.setActiveLayout('mobile')}
							>
								<img src={phone_fill} /> {t('COMMON_MOBILE')}
							</button>
						</div>
						<div className="playerConfigurations-notice">
							{t('SETTINGS_PRESET_CONFIG_PREVIEW_CHANGE_NOTICE')}
						</div>

						{selectedSetting && (
							<div className="playerConfigurations-card-list">
								{Object.keys(advancedSettingTypes)
									.sort(
										(keyA, keyB) =>
											advancedSettingTypesOrder[keyA] - advancedSettingTypesOrder[keyB]
									)
									.map((settingKey, index) => {
										const isCardExpanded = expandedCards.includes(settingKey);
										const controlData = { ...(selectedSetting[settingKey] ?? {}) };
										const publicConfig = publicConfigs.find(
											(c) => c.id === publicConfigIds[this.state.selectedSetting.id]
										);
										const defaultConfigLayout =
											publicConfig &&
											publicConfig.layouts &&
											publicConfig.layouts.find((l) => l.type === activeLayout);
										const shouldDisableRefreshBtn =
											defaultConfigLayout &&
											_.isEqual(controlData, defaultConfigLayout[settingKey]);

										return (
											<div key={index} className="playerConfigurations-card" id={settingKey}>
												{advancedSettingLabels[settingKey] && (
													<div
														className="playerConfigurations-card__header"
														onClick={(e) => this.expandCard(settingKey, e)}
													>
														{headerWithCheckbox[settingKey] ? (
															<FormControlLabel
																control={
																	<Checkbox
																		style={{ color: '#126CFC' }}
																		name="4"
																		checked={controlData.enabled}
																	/>
																}
																onChange={(e, checked) =>
																	this.handleCheckboxChange(
																		checked,
																		settingKey,
																		'enabled'
																	)
																}
																label={
																	<span
																		style={{ fontWeight: '600', fontSize: '14px' }}
																	>
																		{t(advancedSettingLabels[settingKey])}
																	</span>
																}
															/>
														) : (
															t(advancedSettingLabels[settingKey])
														)}
														<div className={'playerConfigurations-card__header-actions'}>
															{isDefaultPlayer && (
																<button
																	disabled={shouldDisableRefreshBtn}
																	className={joinClassNames([
																		'playerConfigurations-card__refresh-btn',
																		shouldDisableRefreshBtn
																			? 'playerConfigurations-card__refresh-btn--disabled'
																			: '',
																	])}
																	onClick={(e) => this.resetToDefault(e, settingKey)}
																>
																	<img src={refresh} />{' '}
																	{t('SETTINGS_PRESET_CONFIG_RESET_TO_DEFAULT')}
																</button>
															)}
															<img
																className={joinClassNames([
																	'playerConfigurations-arrow',
																	isCardExpanded
																		? 'playerConfigurations-arrow--expanded'
																		: '',
																])}
																src={isCardExpanded ? chevron_down : right_arrow}
															/>
														</div>
													</div>
												)}

												{isCardExpanded && (
													<div className="playerConfigurations-card__content">
														<FormGroup>
															{settingKey === advancedSettingTypes.playbackOptions &&
																playbackOptionsKeys.map((dataKey) => {
																	if (dataKey === 'setDefaultVolume') {
																		return (
																			<div
																				key={dataKey}
																				className="playerConfigurations-card__control"
																			>
																				{this.renderFormControlCheckbox(
																					controlData,
																					settingKey,
																					dataKey
																				)}
																				<div className="playerConfigurations-card__color-picker">
																					<input
																						type={'number'}
																						min="0"
																						max="100"
																						className="playerConfigurations-card__opacity"
																						disabled={
																							!controlData.setDefaultVolume
																						}
																						value={
																							controlData?.defaultVolumeValue ??
																							50
																						}
																						onChange={(event) => {
																							this.handlePlayerDefaultVolumechange(
																								event,
																								settingKey,
																								'defaultVolumeValue'
																							);
																						}}
																						onMouseUp={(event) => {
																							// fix value keep increasing/decreasing
																							event.stopPropagation();
																						}}
																					/>
																					<span style={{ color: 'black' }}>
																						%
																					</span>
																				</div>
																			</div>
																		);
																	}

																	return this.renderFormControlCheckbox(
																		controlData,
																		settingKey,
																		dataKey,
																		subDataKeys[dataKey]
																			? { marginLeft: '4px' }
																			: {}
																	);
																})}

															{settingKey === advancedSettingTypes.playCover &&
																playCoverOptionsKeys.map((dataKey) => {
																	if (dataKey === 'playButtonSize') {
																		return (
																			<div
																				key={dataKey}
																				className="playerConfigurations-card__control"
																			>
																				<label
																					id={dataKey + '-select-label'}
																					htmlFor={dataKey + '-select'}
																				>
																					{t(advancedSettingLabels[dataKey])}
																				</label>
																				<div>
																					<FormControlLabel
																						labelPlacement="start"
																						control={
																							<OutlinedInput
																								className="playerConfigurations-card__play-cover-input"
																								endAdornment={
																									<InputAdornment position="end">
																										px
																									</InputAdornment>
																								}
																								value={
																									controlData?.playButtonSizeWidth ??
																									PLAY_COVER_CONSTANTS.playButtonSizeWidth
																								}
																								onChange={(event) => {
																									this.handlePlayerCoverPropsChange(
																										Number(
																											event.target
																												.value
																										),
																										controlData,
																										settingKey,
																										'playButtonSizeWidth'
																									);
																								}}
																							/>
																						}
																					/>
																				</div>
																			</div>
																		);
																	}

																	if (dataKey === 'playButtonPosition') {
																		return (
																			<div
																				key={dataKey}
																				className="playerConfigurations-card__control"
																			>
																				<label
																					id={dataKey + '-select-label'}
																					htmlFor={dataKey + '-select'}
																				>
																					{t(advancedSettingLabels[dataKey])}
																				</label>
																				<div>
																					<FormControlLabel
																						label={
																							<span
																								style={{
																									marginRight: '5px',
																								}}
																							>
																								X
																							</span>
																						}
																						style={{
																							float: 'right',
																						}}
																						labelPlacement="start"
																						control={
																							<OutlinedInput
																								className="playerConfigurations-card__play-cover-input"
																								endAdornment={
																									<InputAdornment position="end">
																										%
																									</InputAdornment>
																								}
																								value={
																									controlData?.playButtonPositionX ??
																									PLAY_COVER_CONSTANTS.playButtonPositionX
																								}
																								onChange={(event) => {
																									this.handlePlayerCoverPropsChange(
																										Number(
																											event.target
																												.value
																										),
																										controlData,
																										settingKey,
																										'playButtonPositionX'
																									);
																								}}
																							/>
																						}
																					/>
																					<FormControlLabel
																						label={
																							<span
																								style={{
																									marginRight: '5px',
																								}}
																							>
																								Y
																							</span>
																						}
																						style={{
																							float: 'right',
																						}}
																						labelPlacement="start"
																						control={
																							<OutlinedInput
																								className="playerConfigurations-card__play-cover-input"
																								endAdornment={
																									<InputAdornment position="end">
																										%
																									</InputAdornment>
																								}
																								value={
																									controlData?.playButtonPositionY ??
																									PLAY_COVER_CONSTANTS.playButtonPositionY
																								}
																								onChange={(event) => {
																									this.handlePlayerCoverPropsChange(
																										Number(
																											event.target
																												.value
																										),
																										controlData,
																										settingKey,
																										'playButtonPositionY'
																									);
																								}}
																							/>
																						}
																					/>
																				</div>
																			</div>
																		);
																	}

																	if (dataKey === 'customPlayButtonIcon') {
																		return (
																			<>
																				<div
																					key={index}
																					className="playerConfigurations-card__control"
																				>
																					{this.renderFormControlCheckbox(
																						controlData ?? {},
																						settingKey,
																						dataKey
																					)}

																					<input
																						style={{ flexBasis: '50%' }}
																						className={joinClassNames(
																							'playerConfigurations-card__accountname-input'
																						)}
																						placeholder={t(
																							advancedSettingLabels.customPlayButtonUrlPlaceholder
																						)}
																						value={
																							controlData?.customPlayButtonUrl ??
																							''
																						}
																						onChange={(e) =>
																							this.handlePlayerCoverPropsChange(
																								e.target.value,
																								controlData,
																								settingKey,
																								'customPlayButtonUrl'
																							)
																						}
																						disabled={
																							!controlData?.customPlayButtonIcon
																						}
																					/>
																				</div>
																			</>
																		);
																	}
																	if (dataKey === 'showVideoLength') {
																		return this.renderFormControlCheckbox(
																			controlData ?? {},
																			settingKey,
																			dataKey
																		);
																	}
																})}

															{settingKey === advancedSettingTypes.liveCountdown &&
																liveCountdownOptionsKeys.map((dataKey) => {
																	if (dataKey === 'coverStyle') {
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																			>
																				<label
																					id={dataKey + '-select-label'}
																					htmlFor={dataKey + '-select'}
																				>
																					{t(advancedSettingLabels[dataKey])}
																				</label>
																				<Select
																					labelId={dataKey + '-select-label'}
																					id={dataKey + '-select'}
																					value={
																						controlData[dataKey] ??
																						'default'
																					}
																					disableUnderline
																					onChange={(event) => {
																						this.handleLiveCountdownPropsChange(
																							event,
																							settingKey,
																							dataKey
																						);
																					}}
																				>
																					{['default', 'custom'].map(
																						(value, index) => (
																							<MenuItem
																								key={index}
																								value={value}
																								className="a11y-lang-select-option"
																							>
																								{value
																									.charAt(0)
																									.toUpperCase() +
																									value.slice(1)}
																							</MenuItem>
																						)
																					)}
																				</Select>
																			</div>
																		);
																	}

																	if (
																		controlData['coverStyle'] &&
																		controlData['coverStyle'] !== 'default'
																	) {
																		if (dataKey === 'customBackground') {
																			return (
																				<div
																					key={dataKey + activeLayout}
																					className="playerConfigurations-card__control"
																				>
																					{this.renderFormControlLiveCountdownCheckbox(
																						controlData ?? {},
																						settingKey,
																						dataKey
																					)}
																					<input
																						className="playerConfigurations-card__accountname-input"
																						value={
																							controlData
																								?.customBackground?.url
																						}
																						onChange={(event) => {
																							this.handleLiveCountdownPropsChange(
																								event,
																								settingKey,
																								dataKey
																							);
																						}}
																					/>
																				</div>
																			);
																		}

																		return (
																			<div
																				key={dataKey + activeLayout}
																				className="playerConfigurations-card__control"
																				style={{ padding: '5px 12px' }}
																			>
																				{this.renderFormControlLiveCountdownCheckbox(
																					controlData ?? {},
																					settingKey,
																					dataKey
																				)}
																				<div>
																					<div>
																						<FormControlLabel
																							label={
																								<span
																									style={{
																										marginRight:
																											'5px',
																									}}
																								>
																									X
																								</span>
																							}
																							style={{
																								float: 'right',
																							}}
																							labelPlacement="start"
																							control={
																								<OutlinedInput
																									className="playerConfigurations-card__play-cover-input"
																									endAdornment={
																										<InputAdornment position="end">
																											%
																										</InputAdornment>
																									}
																									value={
																										controlData[
																											dataKey
																										]?.x
																									}
																									onChange={(
																										event
																									) => {
																										this.handleLiveCountdownPositionPropsChange(
																											Number(
																												event
																													.target
																													.value
																											),
																											controlData,
																											dataKey,
																											'x'
																										);
																									}}
																								/>
																							}
																						/>
																						<FormControlLabel
																							label={
																								<span
																									style={{
																										marginRight:
																											'5px',
																									}}
																								>
																									Y
																								</span>
																							}
																							style={{
																								float: 'right',
																							}}
																							labelPlacement="start"
																							control={
																								<OutlinedInput
																									className="playerConfigurations-card__play-cover-input"
																									endAdornment={
																										<InputAdornment position="end">
																											%
																										</InputAdornment>
																									}
																									value={
																										controlData[
																											dataKey
																										]?.y
																									}
																									onChange={(
																										event
																									) => {
																										this.handleLiveCountdownPositionPropsChange(
																											Number(
																												event
																													.target
																													.value
																											),
																											controlData,
																											dataKey,
																											'y'
																										);
																									}}
																								/>
																							}
																						/>
																					</div>
																					<FormControlLabel
																						label={
																							<span
																								style={{
																									marginRight: '5px',
																								}}
																							>
																								Font size
																							</span>
																						}
																						style={{
																							float: 'right',
																							width: '100%',
																							marginTop: '5px',
																						}}
																						labelPlacement="start"
																						control={
																							<Select
																								labelId={
																									dataKey +
																									'-select-label'
																								}
																								id={dataKey + '-select'}
																								value={
																									controlData[dataKey]
																										?.fontsize ??
																									'large'
																								}
																								disableUnderline
																								onChange={(event) => {
																									this.handleLiveCountdownPositionPropsChange(
																										event.target
																											.value,
																										controlData,
																										dataKey,
																										'fontsize'
																									);
																								}}
																								label="Font size"
																							>
																								{COUNTDOWN_FONT_SIZES.map(
																									(item, index) => (
																										<MenuItem
																											key={index}
																											value={
																												item.value
																											}
																											className="a11y-lang-select-option"
																										>
																											{item.label}
																										</MenuItem>
																									)
																								)}
																							</Select>
																						}
																					/>
																				</div>
																			</div>
																		);
																	}
																})}

															{settingKey === advancedSettingTypes.topControls &&
																topControlsKeys.map((dataKey, index) => {
																	if (dataKey === 'showAccountName') {
																		const displayAccName =
																			languageOfAccountName !== 'all'
																				? controlData.accountName?.[
																						languageOfAccountName
																				  ] ?? ''
																				: typeof controlData.accountName ===
																				  'string'
																				? controlData.accountName
																				: controlData.accountName?.[
																						languageOfAccountName
																				  ] ?? '';
																		return (
																			<>
																				{this.renderFormControlCheckbox(
																					controlData,
																					settingKey,
																					dataKey
																				)}
																				<div
																					key={index}
																					className="playerConfigurations-card__control"
																					style={{
																						marginBottom: '10px',
																						marginTop: '-5px',
																					}}
																				>
																					<input
																						style={{ marginRight: '5px' }}
																						className="playerConfigurations-card__accountname-input"
																						placeholder={t(
																							advancedSettingLabels.showAccountNamePlaceholder
																						)}
																						value={displayAccName}
																						onChange={
																							this.handleAccountNameChange
																						}
																						disabled={
																							!controlData.showAccountName ||
																							!controlData.enabled
																						}
																					/>

																					<Select
																						labelId={
																							dataKey + '-select-label'
																						}
																						id={dataKey + '-select'}
																						value={languageOfAccountName}
																						disableUnderline
																						onChange={
																							this
																								.handleLanguageOfAccountNameChange
																						}
																						placeholder={t(
																							'SETTINGS_PRESET_CONFIG_ACC_NAME_LANGUAGE_PLACEHOLDER'
																						)}
																						disabled={
																							!controlData.showAccountName ||
																							!controlData.enabled
																						}
																					>
																						{[
																							{
																								languageCode: 'all',
																								value: 'All languages',
																							},
																							...a11yLanguages,
																						].map((lang, index) => (
																							<MenuItem
																								key={index}
																								value={
																									lang.languageCode
																								}
																								className="a11y-lang-select-option"
																							>
																								{lang.value}
																							</MenuItem>
																						))}
																					</Select>
																				</div>
																			</>
																		);
																	}

																	if (dataKey === 'showLogotype') {
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																				style={{ marginLeft: 4 }}
																			>
																				{this.renderFormControlCheckbox(
																					controlData,
																					settingKey,
																					dataKey
																				)}
																				<input
																					style={{ flexBasis: '50%' }}
																					className={joinClassNames(
																						'playerConfigurations-card__accountname-input'
																					)}
																					placeholder={t(
																						advancedSettingLabels.logotypeUrlPlaceholder
																					)}
																					value={
																						controlData?.logotypeUrl ?? ''
																					}
																					onChange={(e) => {
																						this.handleTopControlsInputChange(
																							e,
																							'logotypeUrl'
																						);
																					}}
																					disabled={
																						!controlData.showLogotype ||
																						!controlData.enabled
																					}
																				/>
																			</div>
																		);
																	}

																	if (dataKey === 'showExcludingTitleOnDomains') {
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																				style={{ marginLeft: 4 }}
																			>
																				{this.renderFormControlCheckbox(
																					controlData ?? {},
																					settingKey,
																					dataKey
																				)}

																				<input
																					style={{ flexBasis: '50%' }}
																					className={joinClassNames(
																						'playerConfigurations-card__accountname-input'
																					)}
																					placeholder={t(
																						advancedSettingLabels.showExcludingAccountNameOnDomainsPlaceholder
																					)}
																					value={
																						controlData?.excludingTitleOnDomains ??
																						''
																					}
																					onChange={(e) => {
																						this.handleTopControlsInputChange(
																							e,
																							'excludingTitleOnDomains'
																						);
																					}}
																					disabled={
																						!controlData.showExcludingTitleOnDomains ||
																						!controlData.enabled
																					}
																				/>
																			</div>
																		);
																	}

																	if (
																		dataKey === 'showExcludingAccountNameOnDomains'
																	) {
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																				style={{ marginLeft: 4 }}
																			>
																				{this.renderFormControlCheckbox(
																					controlData ?? {},
																					settingKey,
																					dataKey
																				)}

																				<input
																					style={{ flexBasis: '50%' }}
																					className={joinClassNames(
																						'playerConfigurations-card__accountname-input'
																					)}
																					placeholder={t(
																						advancedSettingLabels.showExcludingAccountNameOnDomainsPlaceholder
																					)}
																					value={
																						controlData?.excludingAccountNameOnDomains ??
																						''
																					}
																					onChange={(e) => {
																						this.handleTopControlsInputChange(
																							e,
																							'excludingAccountNameOnDomains'
																						);
																					}}
																					disabled={
																						!controlData.showExcludingAccountNameOnDomains ||
																						!controlData.enabled
																					}
																				/>
																			</div>
																		);
																	}

																	if (dataKey === 'showSolidBackgroundTopControl') {
																		const opacityValue =
																			controlData[opacityKeys[dataKey]];

																		const color =
																			controlData[
																				solidBackgroundStylingKeys[dataKey]
																			] ?? '#000000ff';

																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																			>
																				{this.renderFormControlCheckbox(
																					controlData,
																					settingKey,
																					dataKey
																				)}
																				<div className="playerConfigurations-card__color-picker">
																					<span>{t('COMMON_COLOR')}</span>
																					<ColorPicker
																						width="20px"
																						height="20px"
																						borderRadius="4px"
																						padding="2px"
																						color={color}
																						disableAlpha
																						onChangeComplete={(event) => {
																							if (!controlData.enabled) {
																								return;
																							}
																							this.handleColorPickerChange(
																								event,
																								settingKey,
																								solidBackgroundStylingKeys[
																									dataKey
																								]
																							);
																						}}
																					/>
																					<span>
																						<span>
																							{t('COMMON_TRANSPARENT')}
																						</span>
																					</span>
																					<input
																						type={'number'}
																						min="1"
																						max="100"
																						className="playerConfigurations-card__opacity"
																						value={
																							!opacityValue
																								? 100
																								: opacityValue
																						}
																						onChange={(event) => {
																							this.handleOpacityChange(
																								event,
																								settingKey,
																								dataKey
																							);
																						}}
																						onMouseUp={(event) => {
																							// fix value keep increasing/decreasing
																							event.stopPropagation();
																						}}
																						disabled={!controlData.enabled}
																					/>
																					<span style={{ color: 'black' }}>
																						%
																					</span>
																				</div>
																			</div>
																		);
																	}

																	if (dataKey === 'showSocialSharing') {
																		return (
																			<>
																				<div
																					key={index}
																					className="playerConfigurations-card__control"
																				>
																					{this.renderFormControlCheckbox(
																						controlData,
																						settingKey,
																						dataKey
																					)}

																					<Select
																						labelId={
																							dataKey + '-select-label'
																						}
																						id={dataKey + '-select'}
																						value={
																							controlData.shareSettings
																								?.urlOrigin ??
																							'directLink'
																						}
																						disableUnderline
																						onChange={
																							this
																								.handleShareOriginSelectChange
																						}
																						disabled={
																							!controlData[
																								'showSocialSharing'
																							]
																						}
																					>
																						{SHARE_ORIGIN_ITEMS.map(
																							(item, index) => (
																								<MenuItem
																									key={index}
																									value={item.value}
																									className="a11y-lang-select-option"
																								>
																									{item.label}
																								</MenuItem>
																							)
																						)}
																					</Select>
																				</div>
																				{controlData.shareSettings
																					?.urlOrigin === 'custom' && (
																					<div className="playerConfigurations-card__control playerConfigurations-card__control-end">
																						<input
																							style={{ flexBasis: '50%' }}
																							className="playerConfigurations-card__accountname-input"
																							placeholder={t(
																								advancedSettingLabels.customUrl
																							)}
																							value={
																								controlData
																									.shareSettings
																									?.customUrl
																							}
																							onChange={
																								this
																									.handleShareUrlInputChange
																							}
																						/>
																					</div>
																				)}
																			</>
																		);
																	}

																	return this.renderFormControlCheckbox(
																		controlData,
																		settingKey,
																		dataKey,
																		subDataKeys[dataKey]
																			? { marginLeft: '4px' }
																			: {}
																	);
																})}

															{settingKey === advancedSettingTypes.bottomControls &&
																bottomControlsKeys.map((dataKey) => {
																	if (
																		(dataKey === 'showSocialSharingButton' &&
																			activeLayout === 'desktop') ||
																		dataKey === 'playPausePosition'
																	) {
																		return null;
																	}

																	if (
																		dataKey === 'showSolidBackgroundBottomControl'
																	) {
																		const opacityValue =
																			controlData[opacityKeys[dataKey]];

																		const color =
																			controlData[
																				solidBackgroundStylingKeys[dataKey]
																			] ?? '#000000ff';
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																			>
																				{this.renderFormControlCheckbox(
																					controlData,
																					settingKey,
																					dataKey
																				)}
																				<div className="playerConfigurations-card__color-picker">
																					<span>{t('COMMON_COLOR')}</span>
																					<ColorPicker
																						width="20px"
																						height="20px"
																						borderRadius="4px"
																						padding="2px"
																						color={color}
																						disableAlpha
																						onChangeComplete={(event) => {
																							if (!controlData.enabled) {
																								return;
																							}
																							this.handleColorPickerChange(
																								event,
																								settingKey,
																								solidBackgroundStylingKeys[
																									dataKey
																								]
																							);
																						}}
																					/>
																					<span>
																						<span>
																							{t('COMMON_TRANSPARENT')}
																						</span>
																					</span>
																					<input
																						type={'number'}
																						min="1"
																						max="100"
																						className="playerConfigurations-card__opacity"
																						value={
																							!opacityValue
																								? 100
																								: opacityValue
																						}
																						onChange={(event) =>
																							this.handleOpacityChange(
																								event,
																								settingKey,
																								dataKey
																							)
																						}
																						onMouseUp={(event) => {
																							// fix value keep increasing/decreasing
																							event.stopPropagation();
																						}}
																						disabled={!controlData.enabled}
																					/>
																					<span style={{ color: 'black' }}>
																						%
																					</span>
																				</div>
																			</div>
																		);
																	}

																	if (dataKey === 'showPlayPauseButton') {
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																			>
																				{this.renderFormControlCheckbox(
																					controlData,
																					settingKey,
																					dataKey,
																					subDataKeys[dataKey]
																						? { marginLeft: '4px' }
																						: {}
																				)}
																				{activeLayout === 'mobile' && (
																					<Select
																						labelId={
																							dataKey + '-select-label'
																						}
																						id={dataKey + '-select'}
																						value={
																							controlData[
																								'playPausePosition'
																							] ?? 'bottom'
																						}
																						disableUnderline
																						onChange={
																							this
																								.handleMobilePlayPausePositionChange
																						}
																						disabled={
																							!controlData[
																								'showPlayPauseButton'
																							]
																						}
																						style={{ flexBasis: '50%' }}
																					>
																						{controlPositions.map(
																							(pos, index) => (
																								<MenuItem
																									key={index}
																									value={pos.value}
																									className="a11y-lang-select-option"
																								>
																									{t(pos.label)}
																								</MenuItem>
																							)
																						)}
																					</Select>
																				)}
																			</div>
																		);
																	}

																	return this.renderFormControlCheckbox(
																		controlData,
																		settingKey,
																		dataKey,
																		subDataKeys[dataKey]
																			? { marginLeft: '4px' }
																			: {},
																		dataKey === 'showRewindForwardButtons' &&
																			activeLayout === 'mobile'
																			? controlData['playPausePosition'] ===
																					'center'
																			: undefined
																	);
																})}

															{settingKey === advancedSettingTypes.engagementFeatures &&
																engagementFeaturesKeys.map((dataKey) =>
																	this.renderFormControlCheckbox(
																		controlData,
																		settingKey,
																		dataKey,
																		subDataKeys[dataKey]
																			? { marginLeft: '4px' }
																			: {}
																	)
																)}

															{settingKey ===
																advancedSettingTypes.accessibilitySettings &&
																accessibilitySettingsKeys.map((dataKey, index) => {
																	return (
																		<div
																			key={index}
																			className="playerConfigurations-card__control"
																		>
																			<label
																				id={dataKey + '-select-label'}
																				htmlFor={dataKey + '-select'}
																			>
																				{t(advancedSettingLabels[dataKey])}
																			</label>
																			<Select
																				labelId={dataKey + '-select-label'}
																				id={dataKey + '-select'}
																				value={
																					controlData[dataKey] ?? 'Poppins'
																				}
																				disableUnderline
																				onChange={this.handleA11yLangChange}
																			>
																				{a11yLanguages.map((lang, index) => (
																					<MenuItem
																						key={index}
																						value={lang.languageCode}
																						className="a11y-lang-select-option"
																					>
																						{lang.value}
																					</MenuItem>
																				))}
																			</Select>
																		</div>
																	);
																})}

															{settingKey === advancedSettingTypes.subtitleStyling &&
																subtitleStylingKeys.map((_dataKey, index) => {
																	return (
																		<div
																			key={index}
																			className="playerConfigurations-card__control"
																		>
																			<label
																				id={'subtitlePosition-select-label'}
																				htmlFor={'subtitlePosition-select'}
																			>
																				{t(advancedSettingLabels["subtitlePosition"])}
																			</label>

																			<div>
																				<FormControlLabel
																					label={
																						<span style={{ marginRight: '5px', }}>
																							Y
																						</span>
																					}
																					style={{ float: 'right', }}
																					labelPlacement="start"
																					control={
																						<OutlinedInput
																							className="playerConfigurations-card__play-cover-input"
																							endAdornment={
																								<InputAdornment position="end">
																									%
																								</InputAdornment>
																							}
																							value={controlData?.position?.y}
																							placeholder={0}
																							onChange={(event) => {
																								this.handleSubtitlePositionChange(
																									Number(event.target.value),
																									settingKey,
																									'y'
																								);
																							}}
																						/>
																					}
																				/>
																			</div>
																		</div>
																	);
																})}

															{settingKey === advancedSettingTypes.analyticsSettings &&
																Object.keys(analyticsSettingsKeys).map(
																	(dataKey, index) => {
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																			>
																				<label
																					id={dataKey + '-select-label'}
																					htmlFor={dataKey + '-select'}
																				>
																					{t(advancedSettingLabels[dataKey])}
																				</label>
																				<input
																					className="playerConfigurations-card__accountname-input"
																					value={controlData.gaMeasurementId}
																					onChange={(event) => {
																						this.handleGAMeasurementIdChange(
																							event,
																							settingKey,
																							dataKey
																						);
																					}}
																				/>
																			</div>
																		);
																	}
																)}

															{settingKey === advancedSettingTypes.playerStyling &&
																playerStylingKeys.map((dataKey, index) => {
																	if (dataKey === 'playerFont') {
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																			>
																				<label
																					id={dataKey + '-select-label'}
																					htmlFor={dataKey + '-select'}
																				>
																					{t(advancedSettingLabels[dataKey])}
																				</label>
																				<Select
																					labelId={dataKey + '-select-label'}
																					id={dataKey + '-select'}
																					value={controlData[dataKey]}
																					disableUnderline
																					onChange={
																						this.handlePlayerFontChange
																					}
																					disabled={
																						controlData['useCustomFont']
																					}
																				>
																					{fonts.map((font, index) => (
																						<MenuItem
																							key={index}
																							value={font}
																							className="a11y-lang-select-option"
																						>
																							{font}
																						</MenuItem>
																					))}
																				</Select>
																			</div>
																		);
																	}

																	if (dataKey === 'useCustomFont') {
																		return (
																			<>
																				<div
																					key={index}
																					className="playerConfigurations-card__control"
																				>
																					{this.renderFormControlCheckbox(
																						controlData,
																						settingKey,
																						dataKey
																					)}
																					<input
																						style={{ flexBasis: '50%' }}
																						className={joinClassNames(
																							'playerConfigurations-card__accountname-input',
																							errorMessage.includes(
																								'playerCustomFontName'
																							) &&
																								!controlData.playerCustomFontName
																								? 'playerConfigurations-card__input-error'
																								: ''
																						)}
																						placeholder={t(
																							advancedSettingLabels.playerCustomFontName
																						)}
																						value={
																							controlData.playerCustomFontName
																						}
																						onChange={(e) =>
																							this.handlePlayerCustomFontNameChange(
																								e,
																								controlData
																							)
																						}
																						disabled={
																							!controlData.useCustomFont
																						}
																					/>
																				</div>
																				<div
																					key={index}
																					className="playerConfigurations-card__control playerConfigurations-card__control-end"
																				>
																					<input
																						style={{ flexBasis: '50%' }}
																						className={joinClassNames(
																							'playerConfigurations-card__accountname-input',
																							errorMessage.includes(
																								'playerCustomFontUrl'
																							) &&
																								!controlData.playerCustomFontUrl
																								? 'playerConfigurations-card__input-error'
																								: ''
																						)}
																						placeholder={t(
																							advancedSettingLabels.playerCustomFontUrl
																						)}
																						value={
																							controlData.playerCustomFontUrl
																						}
																						onChange={(e) =>
																							this.handlePlayerCustomFontUrlChange(
																								e,
																								controlData
																							)
																						}
																						disabled={
																							!controlData.useCustomFont
																						}
																					/>
																				</div>
																			</>
																		);
																	}

																	if (dataKey === 'primaryColor') {
																		const opacityValue =
																			controlData[opacityKeys[dataKey]];
																		return (
																			<div
																				key={index}
																				className="playerConfigurations-card__control"
																			>
																				<span>
																					{t(advancedSettingLabels[dataKey])}
																				</span>
																				<div className="playerConfigurations-card__color-picker">
																					<span>{t('COMMON_COLOR')}</span>
																					<ColorPicker
																						width="20px"
																						height="20px"
																						borderRadius="4px"
																						padding="2px"
																						color={controlData[dataKey]}
																						onChangeComplete={(event) =>
																							this.handleColorPickerChange(
																								event,
																								settingKey,
																								dataKey
																							)
																						}
																					/>
																					<input
																						type={'number'}
																						min="1"
																						max="100"
																						className="playerConfigurations-card__opacity"
																						value={
																							!opacityValue
																								? 100
																								: opacityValue
																						}
																						onChange={(event) =>
																							this.handleOpacityChange(
																								event,
																								settingKey,
																								dataKey
																							)
																						}
																						onMouseUp={(event) => {
																							// fix value keep increasing/decreasing
																							event.stopPropagation();
																						}}
																					/>
																					<span style={{ color: 'black' }}>
																						%
																					</span>
																				</div>
																			</div>
																		);
																	}
																})}

															{settingKey === advancedSettingTypes.additionalSettings &&
																additionalSettingsKeys.map((dataKey) =>
																	this.renderFormControlCheckbox(
																		controlData,
																		settingKey,
																		dataKey,
																		subDataKeys[dataKey]
																			? { marginLeft: '4px' }
																			: {}
																	)
																)}
														</FormGroup>
													</div>
												)}
											</div>
										);
									})}
							</div>
						)}
					</>
				)}
			</Box>
		);
	};

	renderEditSavedPresetDialog = () => {
		const {
			savedPresetEditing,
			savedPresetEditNameValue,
			showSaveAsPresetDialog,
			saveAsPresetDialogRecommendedValue,
		} = this.state;
		const { t } = this.props;
		if (!savedPresetEditing && !showSaveAsPresetDialog) {
			return null;
		}

		return (
			<Dialog open={!!savedPresetEditing || showSaveAsPresetDialog} fullWidth={true} maxWidth={dialogSizes.SMALL}>
				<DialogTitle className="dialogTitle" disableTypography>
					<Typography variant="h6">
						{showSaveAsPresetDialog
							? t('SETTINGS_PRESET_CONFIG_SAVE_AS_PRESET_TITLE')
							: t('SETTINGS_PRESET_CONFIG_EDIT_PRESET_TITLE')}
					</Typography>
					<IconButton
						onClick={() =>
							this.setState({
								showSaveAsPresetDialog: false,
								savedPresetEditing: null,
								savedPresetEditNameValue: '',
							})
						}
					>
						<Close />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<div className="dialogContentWrapper">
						<div className="dialogContent__item">
							<label for="saved-preset-name-input">
								{showSaveAsPresetDialog
									? t('SETTINGS_PRESET_CONFIG_NAME_OF_NEW_PRESET')
									: t('SETTINGS_PRESET_CONFIG_NAME_OF_PRESET')}
							</label>
							<input
								id="saved-preset-name-input"
								value={savedPresetEditNameValue}
								onChange={this.handleSavedPresetNameValueChange}
							/>
						</div>
						<div className="dialogContent__item saved-preset-option__action">
							<button
								type="button"
								className="saved-preset-option--star"
								onClick={() =>
									this.setState((prevState) => ({
										saveAsPresetDialogRecommendedValue:
											!prevState.saveAsPresetDialogRecommendedValue,
									}))
								}
							>
								<img src={saveAsPresetDialogRecommendedValue ? star_select : star} />
							</button>
						</div>
					</div>
				</DialogContent>

				<DialogActions>
					<div className="dialogActionsWrapper">
						<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
							<Button
								type="button"
								variant="contained"
								className="defaultActionBtn"
								onClick={this.handleSavePresetModalClick}
							>
								{t('SETTINGS_PRESET_CONFIG_SAVE_AS_PRESET_BUTTON')}
							</Button>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		);
	};

	renderChangeSelectedConfigConfirmDialog = () => {
		const { showChangeSelectedSelectingConfirmDialog } = this.state;
		const { t } = this.props;

		if (!showChangeSelectedSelectingConfirmDialog) {
			return null;
		}

		return (
			<ConfirmationDialog
				openDialog={showChangeSelectedSelectingConfirmDialog}
				title={t('SETTINGS_PRESET_CONFIG_PRESET_CHANGE_SELECTED_CONFIRMATION_TITLE')}
				message={t('SETTINGS_PRESET_CONFIG_PRESET_CHANGE_SELECTED_CONFIRMATION_MESSAGE')}
				callback={this.handleSelectSettingChangeCallback}
				okButtonText={t('COMMON_OK')}
				t={t}
			/>
		);
	};

	handleSelectSettingChangeCallback = (event) => {
		if (!event.ok) {
			this.setState({ showChangeSelectedSelectingConfirmDialog: false });
			return;
		}

		const { showChangeSelectedSelectingConfirmDialog } = this.state;
		this.setState({
			selectedSetting: showChangeSelectedSelectingConfirmDialog,
			originSelectedSettingData: JSON.parse(JSON.stringify(showChangeSelectedSelectingConfirmDialog)),
			activeLayout: 'desktop',
			showChangeSelectedSelectingConfirmDialog: false,
		});
	};

	renderDeleteSavedPresetConfirmDialog = () => {
		const { savedPresetDeleting } = this.state;
		const { t } = this.props;
		if (!savedPresetDeleting) {
			return null;
		}

		return (
			<ConfirmationDialog
				openDialog={!!savedPresetDeleting}
				title={t('SETTINGS_PRESET_CONFIG_PRESET_DELETE_CONFIRMATION_TITLE')}
				message={t('SETTINGS_PRESET_CONFIG_PRESET_DELETE_CONFIRMATION_MESSAGE')}
				callback={this.handleDeleteSavedPreset}
				okButtonText={t('COMMON_DELETE')}
				t={t}
			/>
		);
	};

	renderDefaultPresets = () => {
		const { expandedSections, selectedSetting, advancedSettings, defaultPlayerConfigs } = this.state;
		const { t } = this.props;
		const isPresetsExpanded = expandedSections.includes('presets');
		const defaultConfigs = advancedSettings.filter((s) => defaultPlayerConfigs.includes(s.id));
		const presetConfigs = advancedSettings.filter((s) => !defaultPlayerConfigs.includes(s.id));

		return (
			<Box>
				<div
					className={joinClassNames([
						'playerConfigurations-subheader',
						isPresetsExpanded ? 'playerConfigurations-subheader--expanded' : '',
					])}
					onClick={() => this.expandSubHeader('presets')}
					id="presets"
				>
					{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_PRESET_HEADER')}
					<img
						className={joinClassNames([
							'playerConfigurations-arrow',
							isPresetsExpanded ? 'playerConfigurations-arrow--expanded' : '',
						])}
						src={isPresetsExpanded ? chevron_down : right_arrow}
					/>
				</div>
				{isPresetsExpanded && (
					<>
						{defaultPlayerConfigs && defaultPlayerConfigs.length > 0 && (
							<div className="playerConfigurations-presets">
								{t('SETTINGS_PRESET_CONFIG_DEFAULT_PRESETS')}
								<div className="playerConfigurations-presets__container">
									{defaultConfigs &&
										defaultConfigs.length > 0 &&
										defaultConfigs.map((config) => {
											const isSelected = selectedSetting && config.id === selectedSetting.id;
											return (
												<div
													key={config.id}
													className={joinClassNames([
														'playerConfigurations-presets__item',
														`playerConfigurations-presets__item--${config.id.toLowerCase()}`,
														isSelected
															? 'playerConfigurations-presets__item--selected'
															: '',
														config.recommended
															? 'playerConfigurations-presets__item--recommended'
															: '',
													])}
													onClick={() => this.selectSetting(config)}
												>
													<div className="playerConfigurations-presets__item__info">
														<button
															type="button"
															onClick={(e) => this.setRecommendedSetting(e, config)}
														>
															<img src={config.recommended ? star_select : star} />
														</button>
													</div>
													<div className="playerConfigurations-presets__item__label">
														<div className="playerConfigurations-presets__item__label--title">
															{defaultSettingTypes[config.id.toLowerCase()].name}
														</div>
														{t(defaultSettingTypes[config.id.toLowerCase()].note)}
													</div>
												</div>
											);
										})}
								</div>
							</div>
						)}
						<div className="saved-presets">
							<FormControl fullWidth className="saved-preset-select__control">
								<label id="saved-preset-select-label" htmlFor="saved-preset-select">
									{t('SETTINGS_PRESET_CONFIG_CUSTOM_PRESETS')}
								</label>
								<Select
									labelId="saved-preset-select-label"
									id="saved-preset-select"
									value={
										selectedSetting && !!presetConfigs.find((s) => selectedSetting.id === s.id)
											? selectedSetting.id
											: 0
									}
									onChange={this.handleOnSelectSavedPreset}
									disableUnderline
								>
									<MenuItem disabled value={0} className="saved-preset-option">
										{`-${t('SETTINGS_PRESET_CONFIG_LABEL_CHOOSE_A_PRESET')}-`}
									</MenuItem>
									{presetConfigs.map((config) => {
										return (
											<MenuItem value={config.id} className="saved-preset-option" key={config.id}>
												<div className="saved-preset-option__content">
													{config.name}
													<div className="saved-preset-option__action">
														<button
															type="button"
															className="saved-preset-option--edit"
															onClick={(e) =>
																this.handleEditSavedPresetNameClick(e, config)
															}
														>
															<img src={edit} />
														</button>
														<button
															type="button"
															className="saved-preset-option--star"
															onClick={(e) => this.setRecommendedSetting(e, config)}
														>
															<img src={config.recommended ? star_select : star} />
														</button>
														<button
															type="button"
															className="saved-preset-option--delete"
															onClick={(e) =>
																this.handleDeleteSavedPresetNameClick(e, config)
															}
														>
															<img src={delete_icon} />
														</button>
													</div>
												</div>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
					</>
				)}
				{this.renderEditSavedPresetDialog()}
				{this.renderDeleteSavedPresetConfirmDialog()}
				{this.renderChangeSelectedConfigConfirmDialog()}
			</Box>
		);
	};

	renderLivePlayer = (_) => {
		const { defaultAccountId } = this.props;
		const { videoWidth, videoHeight, latestMedia, selectedSetting, activeLayout } = this.state;

		if (!latestMedia || !videoWidth || !videoHeight || !selectedSetting) {
			return null;
		}

		const selectedLayout = selectedSetting?.layouts?.find((l) => l.type === activeLayout);

		const isTheoSetting = selectedSetting.id === THEO_PLAYER_PRESET_ID;

		if (isTheoSetting) {
			return (
				<div id="playerConfigurations-livePlayer" ref={(r) => (this.inspectViewLivePlayerRef = r)}>
					<div className="LivePlayerWrapper">
						<div
							id="player-preview"
							bonnier-player-media-id={latestMedia}
							account={defaultAccountId}
							style={{ width: videoWidth + 'px', height: videoHeight + 'px' }}
						/>
					</div>
				</div>
			);
		}

		return (
			<div id="playerConfigurations-livePlayer" ref={(r) => (this.inspectViewLivePlayerRef = r)}>
				<div className="LivePlayerWrapper">
					<div
						id="player-preview"
						data-qplayer-id={'player-config-preview'}
						data-qplayer-media-id={latestMedia}
						data-qplayer-account-id={defaultAccountId}
						data-qplayer-preset-id={selectedSetting?.id}
						data-qplayer-inline={!!selectedLayout?.playbackOptions?.softFullscreen ? 'off' : 'on'}
						data-qplayer-thumbnail-width="100%"
						data-qplayer-thumbnail-height="100%"
						data-qplayer-analytics={'off'}
						data-qplayer-test={process.env.NODE_ENV !== 'production' ? 'true' : 'false'}
					/>
				</div>
			</div>
		);
	};

	render() {
		const { t } = this.props;
		const { loading, selectedSetting, originSelectedSettingData, advancedSettings } = this.state;
		const isUsingTheoPlayer = selectedSetting && selectedSetting.id === THEO_PLAYER_PRESET_ID;

		if (loading) {
			return (
				<LoadingOverlay
					className="loadingStreamOverlay"
					active={true}
					spinner={<ScaleLoader color={'#ffffff'} />}
					text={'Loading data...'}
				/>
			);
		}

		if (!advancedSettings || advancedSettings.length === 0) {
			return (
				<div className="playerConfigurations__no-data">
					<img src={no_medias_icon} />
					<label> {t('MEDIA_LIBRARY_MEDIA_DISPLAY_THERE_IS_NOTHING_HERE')}</label>
				</div>
			);
		}

		return (
			<>
				{isUsingTheoPlayer && (
					<Helmet>
						<script
							src={selectedSetting.layouts[0]?.playerStyling?.script ?? DEFAULT_THEO_PLAYER_EMBED}
							type="text/javascript"
							async="true"
						/>
					</Helmet>
				)}
				<div className="playerConfigurations">
					<div className="playerConfigurations-main">
						<div className="playerConfigurations-main__header">{t('SETTINGS_PLAYER_CONFIG_HEADER')}</div>

						{this.renderDefaultPresets()}
						{this.renderAdvancedSettings()}

						{!_.isEqual(selectedSetting, originSelectedSettingData) && (
							<div className="playerConfigurations-actions">
								<span className="playerConfigurations-actions__change-notice">
									{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_CHANGE_NOTICE')}
								</span>
								<Button
									type="button"
									variant="contained"
									className="defaultActionBtn squireBtn"
									onClick={this.handleSavePlayerSetting}
								>
									{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_SAVE_CHANGE_BTN')}
								</Button>
								<Button
									type="button"
									variant="contained"
									className="defaultActionBtn squireBtn"
									onClick={this.handleSaveAsNewPresetClick}
								>
									{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_SAVE_AS_PRESET_BTN')}
								</Button>
							</div>
						)}
					</div>
					<div className="playerConfigurations-preview">{this.renderLivePlayer()}</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	loadMediasForPagination: (accountId, mediaSet, offset, mediaName, sortType) =>
		dispatch(loadMediasForPagination(accountId, mediaSet, offset, mediaName, sortType)),
	loadPlayerConfiguration: (accountId) => dispatch(loadPlayerConfiguration(accountId)),
	loadPlayerConfigurationById: (accountId, playerConfigId) =>
		dispatch(loadPlayerConfigurationById(accountId, playerConfigId)),
	addNewPlayerConfiguration: (accountId, body) => dispatch(addNewPlayerConfiguration(accountId, body)),
	updatePlayerConfiguration: (accountId, playerConfigId, body) =>
		dispatch(updatePlayerConfiguration(accountId, playerConfigId, body)),
	deletePlayerConfiguration: (accountId, playerConfigId) =>
		dispatch(deletePlayerConfiguration(accountId, playerConfigId)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	getAccountSettings: (accountId) => dispatch(getAccountSettings(accountId)),
	getPublicPlayerConfigurations: (playerConfigId) => dispatch(getPublicPlayerConfigurations(playerConfigId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PlayerConfigurations);
